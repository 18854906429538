import React, {Component} from 'react';
import {Container, FormLabel, Image, Row} from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import {Logger} from 'react-logger-lib';
import {connect} from 'react-redux';
import {getEntities, selectAllEntities, selectPropertiesEntities} from '../features/entities/entitiesSlice';
import {selectEntitiesByIds, selectPropertyByKeyFromProps} from '../features/selectors';
import {objIsNotEmpy} from '../lib/utils';
import {STYLE_PROJECT_BOX, STYLE_PROJECT_COLUMN, STYLE_PROJECT_CONTAINER, STYLE_PROJECT_ROW} from '../style';
import {Properties} from './common/Properties';

localStorage.setItem('App.Project', 'INFO'); //WARN INFO TRACE


class Project extends Component {
    // constructor(props) {
    //     super(props);
    // }

    componentDidMount() {
        Logger.of('App.Project.componentDidMount').info();
        this.props.dispatch(getEntities());

    }

    createBox(text) {
        return (
            <h3 style={STYLE_PROJECT_BOX}>{text}</h3>
        );
    }

    createHome() {
        Logger.of('App.Project.createHome').info();
        const {entities, properties: allProperties} = this.props;

        let home_entity = Object.entries(entities).find((value) => {
            return value[1].id === 501;
        })

        let homeProps = {};
        let homeName = '';
        if (objIsNotEmpy(home_entity)) {
            let home = home_entity[1];
            const {name, properties: homePropertiesIds} = home;
            homeProps = selectEntitiesByIds(allProperties, homePropertiesIds);
            // let {value: address = ''} = selectPropertyByKeyFromProps(homeProps, 'address');
            // let {value: lot = ''} = selectPropertyByKeyFromProps(homeProps, 'lot');
            let {value: image = ''} = selectPropertyByKeyFromProps(homeProps, 'image');
            // image = '/images/72810734_0.jpg';

            // homeName = name;
            const items = <Properties properties={homeProps}/>
            return (
                <div>
                    <h3>Home</h3>
                    {items}
                    <Image src={image} style={{width:'120px'}}/>
                </div>
            )
        }

        return (
            <h3>Home</h3>
        )
    }

    render() {
        Logger.of('App.Project.render').info();
        return (
            <div>
                {this.createHome()}

                <Container style={STYLE_PROJECT_CONTAINER}>
                    <Row style={STYLE_PROJECT_ROW}>
                        <FormLabel>Projects:</FormLabel>
                    </Row>
                    <Row>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("Requirements")}</Col>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("Tasks and Checks")}</Col>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("Product")}</Col>
                    </Row>
                    <Row>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("What I Want")}</Col>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("Will buy")}</Col>
                        <Col style={STYLE_PROJECT_COLUMN}>{this.createBox("Available on Market")}</Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

Project.propTypes = {};

// export default Project;
//
const mapStateToProps = (state) => {
    Logger.of('App.Project.mapStateToProps').info('state', state);
    // const {streams: streamsState, events: eventsState, planned: plannedState, layouts: layoutsState, ranges} = state;
    // const {entities: streams, selected_stream = MAIN} = streamsState;
    // const {entities: events} = eventsState;
    // const {entities: planned} = plannedState;
    // const {entities: layouts} = layoutsState;

    // const activities = selectActivities(state);
    const entities = selectAllEntities(state);
    const properties = selectPropertiesEntities(state); // props entites

    // return {events, planned, layouts, ranges, selected_stream, activities};
    return {entities, properties};
};

export default connect(mapStateToProps,)(Project);


