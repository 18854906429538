//
// https://stackoverflow.com/questions/48156902/how-can-i-draw-red-horizontal-line-in-react
//


export const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 5
        }}
    />
);
