import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Logger} from 'react-logger-lib';
import {connect} from 'react-redux';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import {ColoredLine} from './ColoredLine';
import {HHmm, IMPORT_SOURCE, MMDDYYYY, USER, YYYY_MM_DD} from '../constants';
import {getActivitiesAndEvents, postActivitiesAndGetEvents} from "../features/activities/activitiesSlice";
import ActivityHealth from "./ActivityHealth";
import {getTime} from "../lib/utils";
import ToggleButton from "react-bootstrap/ToggleButton";


localStorage.setItem('App.Activity', 'INFO'); //WARN INFO TRACE

class Activity extends Component {
    static propsTypes = {
        test_id: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.postEventEndTime = this.postEventEndTime.bind(this);

    }

    componentDidMount() {
        Logger.of('App.Activity.componentDidMount').info();
        this.props.dispatch(getActivitiesAndEvents());
    }

    submitActivity(e) {
        Logger.of('App.Activity.submitActivity').info();
        let date = e.target[0].value;
        let start_time = e.target[1].value;
        let end_time = e.target[2].value;
        let activity_obj = e.target[3][e.target[3].selectedIndex]
        let activity_id = parseInt(activity_obj.id)
        let note = e.target[4].value;
        // let start = moment(date + ' ' + start_time);
        // let end = moment(date + ' ' + end_time);
        let o = {
            start: getTime(date + ' ' + start_time).format(),
            // end: end.format(),
            activity_id,
            // note
            owner_id: USER.id,
            source_id: IMPORT_SOURCE.id
        };

        if (end_time) {
            o.end = getTime(date + ' ' + end_time).format()
        }
        if (note) {
            o.note = note
        }

        this.props.dispatch(postActivitiesAndGetEvents(o));
    }

    submitFood(e) {
        // let date = moment();
        // let start_time = e.target[1].value;
        // let end_time = e.target[2].value;
        // let activity_obj = e.target[3][e.target[3].selectedIndex]
        let activity_id = 9 // Personal 7 //Health
        let note = 'lunch'; // e.target[4].value;
        // let start = moment(date + ' ' + start_time);
        // let end = moment(date + ' ' + end_time);
        let o = {
            // start: moment(date + ' ' + start_time).format(),
            start: getTime().format(),
            // end: end.format(),
            activity_id,
            note
        };

        // if (end_time) {
        //     o.end = moment(date + ' ' + end_time).format()
        // }
        // if (note) {
        //     o.note = note
        // }

        this.props.dispatch(postActivitiesAndGetEvents(o));
    }

    postEventEndTime(e, id) {
        Logger.of('App.Activity.postEventEndTime').info('id', id);
    }

    createRow(row) {
        return (
            <Row fluid>
                <Col md='auto'>{row[0]}</Col>
                <Col md='auto'>{row[1]}</Col>
                <Col md='auto'>{row[2]}</Col>
                <Col md='auto'>{row[3]}</Col>
                <Col md='auto'>{row[4]}</Col>
                <Col md='auto'>{row[5]}</Col>
            </Row>
        )
    }

    createActivitiesFromEvents(events) {
        let activities_components = [];
        if (events) {
            const {activities} = this.props;
            Object.entries(events).forEach(value => {
                    const {id, start, end, activity: activity_id, note} = value[1];
                    let activity = activities[activity_id]
                    let mstartday = getTime(start).format(MMDDYYYY);
                    let mstart = getTime(start).format(HHmm);
                    let mend = end ? getTime(end).format(HHmm) : "";
                    let frm = 'auto'
                    let frm1 = 'auto'  // 5 //
                    activities_components.push(
                        <Row>
                            <Col xs={frm1} md={frm}>{mstartday}</Col>
                            <Col xs={frm1} md={frm}>{mstart}</Col>
                            <Col xs={frm1} md={frm}>{
                                end ? mend :
                                    <Button small onClick={this.postEventEndTime.bind(id)}>{getTime().format(HHmm)}</Button>
                                }</Col>
                            <Col xs={frm1} md={frm}>{activity.name}</Col>
                            <Col xs={frm1} md={frm}>{id},{note}</Col>
                            <Col xs={frm1} md={frm}></Col>
                        </Row>
                    )
                }
            );
        }
        return activities_components;
    }

    createStreams(activities) {
        if (activities) {
            return Object.entries(activities).map(value => {
                return (<option id={value[1].id}>{value[1].name}</option>)
            });
        }

        return [];
    }

    render() {
        const date_time_now = getTime();
        let date_start = date_time_now.format(YYYY_MM_DD);
        let time_start = date_time_now.format(HHmm);

        // , stats=''.concat(date_start, ' ', time_start)
        const {events, eventsErrors,  activities} = this.props;
        return (
            <div>
                <h3 style={{color:"red"}}>{eventsErrors}</h3>
                <Accordion defaultActiveKey="0">
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                Activity
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                {this.createRow(['Date', 'Start', 'End', 'Activity', 'Note', ''])}
                                <ColoredLine color="red>"/>
                                {this.createActivitiesFromEvents(events)}
                                <Form onSubmit={(e) => this.submitActivity(e)}>
                                    <Form.Group controlId="formStart">
                                        <Form.Row>
                                            <Col md='auto'>
                                                <Form.Control type="date" placeholder="Enter start date"
                                                              defaultValue={date_start}/>
                                            </Col>
                                            <Col md='auto'>
                                                <Form.Control type="time" placeholder="Enter start time"
                                                              defaultValue={time_start}/>
                                            </Col>
                                            <Col md='auto'>
                                                <Form.Control type="time" placeholder="Enter end time"/>
                                            </Col>
                                            <Col md='auto'>
                                                <Form.Control as="select" custom>
                                                    {this.createStreams(activities)}
                                                </Form.Control>
                                            </Col>
                                            <Col md='auto'>
                                                <Form.Control type="text" placeholder="Enter Note"/>
                                            </Col>
                                            <Col md='auto'>
                                                <Button variant="primary" type="submit">
                                                    +
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                    </Form.Group>
                                </Form>
                                <ToggleButton>Work</ToggleButton>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Food
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>Add food I have now
                                <Form onSubmit={(e) => this.submitFood(e)}>
                                    <Form.Group controlId="formStart">
                                        <Form.Control as="select" custom>
                                            {this.createStreams(activities)}
                                        </Form.Control>

                                        <Button variant="primary" type="submit">
                                            +
                                        </Button>
                                    </Form.Group>
                                </Form>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                Health
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <ActivityHealth/>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.Activity.mapStateToProps').info('state', state);
    const {events: eventsState, activities: activitiesState} = state;
    const {entities: events, error: eventsErrors} = eventsState;
    const {entities: activities, stats} = activitiesState;
    return {events, eventsErrors, activities, stats};
};

export default connect(mapStateToProps,)(Activity);
