
// Time formats
export const YYYYMMDDHHmmss = "YYYY-MM-DD HH:mm:ss";
export const YYYY_MM_DD = 'YYYY-MM-DD';
export const MMDDYYYY = 'MM/DD/YYYY';
export const HHmm = 'HH:mm';
export const MMDD = 'MM/DD';

// Calendar direction
export const HORIZONTAL = 1;
export const VERTICAL = 2;

export const HOUR_HEIGHT_PX = 50;
export const CARD_WIDTH_PX = 300;

export const CARD_IMAGE_SIZE = 24;

export const EVENT_OCCURRED_LEFT_PX = 75;
export const EVENT_PLANNED_LEFT_PX = 300;

export const MAIN = {
    id: 0,
    name: 'Main'
}

export const USER = {
    id: 2,
    name: 'ds'
}


export const IMPORT_SOURCE = {
    id: 1,
    name: 'Manual'
}


export const API_EVENT_POS_URL = '/api/event_pos/';
export const API_PLANNED_POS_URL = '/api/planned_pos/';
export const API_PLANNED_URL = '/api/planned/';
export const API_EVENTS_URL = '/api/events/';
export const API_ACTIVITIES_URL = '/api/activities/';
export const API_ACTIVITIES_HEALTH_STAT_URL = '/api/activities/health/stat';
export const API_STREAMS_URL = '/api/streams';
export const API_STREAM_EVENTS_URL = '/api/events/stream/';
export const API_STREAM = "#/stream/";
export const API_STORAGE_HOSTS_URL = '/api/storage/hosts/';
export const API_STORAGE_DIRECTORIES_URL = '/api/storage/hosts/${host}/dir?path=${path}';
export const API_STORAGE_FILES_URL = '/api/storage/hosts/${host}/files?path=${path}';
export const API_ENTITIES_URL = '/api/entities/';

export const API_AUTH = "/rest-auth/google/";
