import {Logger} from "react-logger-lib";
import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {client} from "../../api/client";
import {getEvents} from "../events/eventsSlice";
import {normalize} from "normalizr";
import {tagsSchema} from "../../app/schemas";
import {API_STREAMS_URL} from "../../constants";
import {objIsNotEmpy} from "../../lib/utils";

localStorage.setItem('App.streamsSlice', 'WARN'); //WARN INFO TRACE


export const streamsAdapter = createEntityAdapter();
const streamsInitialState = streamsAdapter.getInitialState({status: null, selected_stream: null});


export const getStreams = createAsyncThunk(
    'streams/getStreams',
    async (data, {dispatch, getState}) => {
        Logger.of('App.streamsSlice.getStreams').info('data', data);
        let streams = await client.get(API_STREAMS_URL).then(
            (res) => res
        )
        const normalized = normalize(streams, tagsSchema)
        return normalized.entities
    }
)

export const getStreamsAndSelect = createAsyncThunk(
    'streams/getStreamsAndSelect',
    async (data, {dispatch, getState}) => {
        Logger.of('App.streamsSlice.getStreams').info('data', data);
        dispatch(getStreams(data)).then( (res) => {
            const {type, payload} = res;
            if (objIsNotEmpy(payload)) {
                let {tags: streams} = payload;
                if (streams) {
                    let stream = Object.values(streams)[0];
                    dispatch(selectStream({stream}))
                }
            }
        })
    }
)


export const selectStream = createAsyncThunk(
    'streams/selectStream',
    async (data, {dispatch, getState}) => {
        Logger.of('App.streamsSlice.selectStream').info('data', data);
        const {stream, ranges} = data;
        dispatch(getEvents({stream, ranges}));
        // history.push(`/stream/${stream.id}`);
        return stream;
    }
)


const streamsSlice = createSlice({
    name: 'streams',
    initialState: streamsInitialState,
    reducers: {
    },
    extraReducers: {
        [selectStream.pending]: (state, action) => {
            state.status = 'loading'
        },
        [selectStream.fulfilled]: (state, {payload}) => {
            Logger.of('App.streamsSlice.selectStream').info('action', payload);
            state.selected_stream = payload;
            state.status = 'success'
        },
        [selectStream.rejected]: (state, action) => {
            state.status = 'failed'
        },

        [getEvents.fulfilled]: (state, {payload}) => {
            Logger.of('App.streamsSlice.getEvents.fulfilled').info('payload.tags', payload.tags);
            if (payload.tags) {
                streamsAdapter.upsertMany(state, payload.tags);
            }
        },

        [getStreams.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getStreams.fulfilled]: (state, {payload}) => {
            Logger.of('App.streamsSlice.getStreams.fulfilled').info('payload.tags',
                payload.tags);
            streamsAdapter.upsertMany(state, payload.tags)
            state.status = 'success'
        },
        [getStreams.rejected]: (state, action) => {
            state.status = 'failed'
        },
    },
})

// export const {
//   selectById: selectStreamById,
//   selectIds: selectStreamIds,
//   selectEntities: selectStreamEntities,
//   selectAll: selectAllStreams,
//   selectTotal: selectTotalStreams
// } = streamsAdapter.getSelectors(state => state.tag)

// export const {fetchStart, updateStrema: updateStream, fetchStreamsSuccess, fetchStreamsFailure, fetchStop} = streamSlice.actions;
// export const {selectStream} = streamsSlice.actions;

export default streamsSlice.reducer;
