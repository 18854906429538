import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import googleLogin from "./googleLoginService";

class GoogleSocialAuth extends Component {

    render() {
        const googleResponseOnSuccess = async(response) => {
          let googleResponse  = await googleLogin(response.accessToken)
          console.log(googleResponse);
          console.log(response);
        }

        const googleResponseOnFailure = (response) => {
            console.log('googleResponse', response);
            // return true;
        }
        //  className="App"
        return (
            <div>
                <h1>LOGIN WITH GOOGLE</h1>
                <GoogleLogin
                    clientId="226024729245-qpk5j4q801r4p1a52jbdsc99fcl041li.apps.googleusercontent.com"
                    buttonText="LOGIN WITH GOOGLE"
                    onSuccess={googleResponseOnSuccess}
                    onFailure={googleResponseOnFailure}
                />
            </div>
        );
    }
}

export default GoogleSocialAuth;
