import {schema} from "normalizr";

export const tagSchema = new schema.Entity('tags');
export const tagsSchema = [tagSchema]

export const activitySchema = new schema.Entity('activity');
export const activitiesSchema = [activitySchema]

// position saved in DB for event per stream/view/etc
export const positionSchema = new schema.Entity('positions');
export const positionsSchema = [positionSchema];

export const eventSchema = new schema.Entity('events', {
    activity: activitySchema,
    positions: positionsSchema,
    tags: tagsSchema
});
export const eventsSchema = [eventSchema]

// calculated for UI, use event_id as id for position, the same
export const layoutSchema = new schema.Entity('layouts'); //, {}, {idAttribute: 'event_id'});

export const fileSchema = new schema.Entity('files'); //, {}, {idAttribute: 'event_id'});
export const filesSchema = [fileSchema];


// export const dirSchema = new schema.Entity('dirs', {
//     tags: tagsSchema,
//     files: filesSchema
// });
// export const dirsSchema = [dirSchema]


// export const hostSchema1 = new schema.Entity('hosts',{
//     directories: [dirSchema],
//     tags: tagsSchema
// });

export const propertySchema = new schema.Entity('properties');
export const propertiesSchema = [propertySchema];


export const itemSchema = new schema.Entity('items', {
    properties: propertiesSchema,
    tags: tagsSchema,
    // files: filesSchema
});
export const itemsSchema = [itemSchema];

export const dirSchema = new schema.Entity('dirs', {
    properties: propertiesSchema,
    files: filesSchema,
    // files: filesSchema
});
export const dirsSchema = [dirSchema];

export const hostSchema = new schema.Entity('hosts',{
    properties: propertiesSchema,
    dirs: dirsSchema,
    items: itemsSchema
});
export const hostsSchema = [hostSchema]


export const docSchema = new schema.Entity('docs'); //, {}, {idAttribute: 'event_id'});

export const layoutsSchema = [layoutSchema]
export const docsSchema = [docSchema]

export const entitySchema = new schema.Entity('entities',{
    properties: propertiesSchema,
    // items: itemsSchema
});
export const entitiesSchema = [entitySchema];
