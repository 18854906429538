import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Logger} from "react-logger-lib";
import {client} from "../../api/client";
import {getEvents, postEvent} from "../events/eventsSlice";
import {normalize} from "normalizr";
import {activitiesSchema} from "../../app/schemas";
import {API_ACTIVITIES_HEALTH_STAT_URL, API_ACTIVITIES_URL} from "../../constants";
import {getPlanned} from "../planned/plannedSlice";

export const activitiesAdapter = createEntityAdapter();
export const activitiesInitialState = activitiesAdapter.getInitialState({status: null, stats: null});


export const getActivities = createAsyncThunk(
    'events/getActivities',
    async (data, {dispatch, getState}) => {
        Logger.of('App.activitiesSlice.getActivities').info('data', data);
        let activities = await client.get(API_ACTIVITIES_URL).then(
            (res) => res
        )
        const normalized = normalize(activities, activitiesSchema)
        return normalized.entities
    }
)


export const getActivitiesAndEvents = createAsyncThunk(
    'events/getActivitiesAndEvents',
    async (data, {dispatch, getState}) => {
        Logger.of('App.activitiesSlice.getActivitiesAndEvents').info('data', data);
        dispatch(getActivities(data))
        dispatch(getEvents({activities: true}))
        dispatch(getActivitiesStat(data));
    }
)


export const postActivitiesAndGetEvents = createAsyncThunk(
    'events/getActivitiesAndEvents',
    async (data, {dispatch, getState}) => {
        Logger.of('App.activitiesSlice.postActivitiesAndGetEvents').info('data', data);
        dispatch(postEvent(data))
        dispatch(getEvents({activities: true}))
    }
)


export const getActivitiesStat = createAsyncThunk(
    'events/getActivitiesStat',
    async (data, {dispatch, getState}) => {
        Logger.of('App.activitiesSlice.getActivitiesStat').info('data', data);
        // dispatch(selectStream(MAIN));
        let activities = await client.get(API_ACTIVITIES_HEALTH_STAT_URL).then(
            (res) => res
        )
        return activities;
    }
)

const activitiesSlice = createSlice({
    name: 'activities',
    initialState: activitiesInitialState,
    reducers : {
    },
    extraReducers: {
        [getEvents.fulfilled]: (state, {payload}) => {
            if (payload.activity) {
                activitiesAdapter.upsertMany(state, payload.activity)
            }
        },
        [getPlanned.fulfilled]: (state, {payload}) => {
            if (payload.activity) {
                activitiesAdapter.upsertMany(state, payload.activity)
            }
        },

        [getActivities.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getActivities.fulfilled]: (state, {payload}) => {
        Logger.of('App.activitiesSlice.getActivities.fulfilled').info('payload', payload);
            if (payload.activity) {
                activitiesAdapter.setAll(state, payload.activity)
            } else {
                activitiesAdapter.removeAll(state);
            }
            state.status = 'success'
        },
        [getActivities.rejected]: (state, payload) => {
        Logger.of('App.activitiesSlice.getActivities.rejected').info('payload', payload);
            state.status = 'failed'
        },
        [getActivitiesStat.pending]: (state, action) => {
            state.status = 'loading'
        },
        [getActivitiesStat.fulfilled]: (state, {payload}) => {
        Logger.of('App.activitiesSlice.getActivitiesStat.fulfilled').info('payload', payload);
            state.stats = payload;
            state.status = 'success'
        },
        [getActivitiesStat.rejected]: (state, payload) => {
        Logger.of('App.activitiesSlice.getActivitiesStat.rejected').info('payload', payload);
            state.status = 'failed'
        },
    }
})


export const selectActivities = (state) => {
    return state.activities.entities;
}


export const {
    pending: getActivitiesStart,
    fulfilled: getActivitiesDone,
    rejected: getActivitiesFailed} = getActivities;

export default activitiesSlice.reducer;
