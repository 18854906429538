import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import {STYLE_PROPERTIES, STYLE_PROPERTY} from "../../style";
import Button from "react-bootstrap/Button";
import {objIsNotEmpy} from "../../lib/utils";


export const Property = (props) => {
    const {property, style=STYLE_PROPERTY} = props;
    const [key, value] = property;
    return (
        <li style={style}>
            {key}: {value}
        </li>
    )
}


export const Properties = (props) => {
    const {properties = {}, style=STYLE_PROPERTIES} = props;

    return (
        <ul style={style}>
            {Object.entries(properties).map((value, index) => {
                return (
                    <Property property={value}/>
                )
            })}
        </ul>
    )
}

// export const useCollapsedItems = (props) => {
//     const {name, items, onOpenItemLoad, initState = false} = props;
//
//     const [openItem, setOpenItem] = useState(initState);
//     const onOpenItem = () => {
//         if (!openItem && onOpenItemLoad) {
//             onOpenItemLoad(name, setOpenItem);
//             // dispatch(getDirectories({name, dir: ''})).then(res => {
//             //     setDirectories(res);
//             // });
//         }
//         setOpenItem(!openItem);
//     };
//
//     let area_id = 'area-collapse-'.concat(name);
//
//     const button =
//         <Button size="sm" variant="light"
//                 onClick={onOpenItem}
//                 aria-controls={area_id}
//                 aria-expanded={openItem}>{openItem ? '-' : '+'}</Button>
//     const area =
//         <Collapse in={openItem}>
//             <div id={area_id}>
//                 {items}
//             </div>
//         </Collapse>
//
//     return [button, area];
// }
