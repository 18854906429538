// noinspection CheckTagEmptyBody

import {Button} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {finance, health, setting, travel} from '../../images';
import './HomePage.css';

// class HomePage extends React.Component {
const HomePage = ({streamsProp, theme}) => {

    const history = useHistory();
    return (
        // <div style={{ backgroundImage: `url(${background})` }}>
        <div style={{
            width: 640,
            height: 400,
            backgroundImage: 'matrix-background-streaming-binary-code-falling-vector-21415282.jpg',
        }}>
            <div className="image-mosaic">
                <Button className="card card-tall card-wide" style={{backgroundImage: `url(${travel})`}}>
                    Chicago
                </Button>
                <Button className="card" style={{backgroundImage: `url(${finance})`}}>
                    Finance
                </Button>
                <Button className="card" style={{backgroundImage: `url(${health})`}}>
                    Health
                </Button>
                <Button className="card" style={{backgroundImage: `url(${travel})`}}
                >
                </Button>
                <Button className="card" style={{backgroundImage: `url(${travel})`}}
                >
                </Button>
                <Button className="card" style={{backgroundImage: `url(${setting})`}}
                        onClick={() => (history.push('/docs'))}>
                    Setting
                </Button>
            </div>
        </div>
    );
};

export default HomePage;

//                 <Accordion defaultActiveKey="10">
//                     <Card>
//                         <Card.Header>
//                             <Accordion.Toggle as={Button} variant="link" eventKey="0">
//                                 Calendars
//                             </Accordion.Toggle>
//                         </Card.Header>
//                         <Accordion.Collapse eventKey="0">
//                             <Nav className="flex-column">
//                                 <Nav.Link href="#today">Today Schedule</Nav.Link>
//                                 <Nav.Link href="#plan">Plan Schedule</Nav.Link>
//                             </Nav>
//                         </Accordion.Collapse>
//                     </Card>
//                     <Card>
//                         <Card.Header>
//                             <Accordion.Toggle as={Button} variant="link" eventKey="1">
//                                 Financial Streams
//                             </Accordion.Toggle>
//                         </Card.Header>
//                         <Accordion.Collapse eventKey="1">
//                             <Nav className="flex-column">
//                                 <Nav.Link href="#project_home">Find Home</Nav.Link>
//                                 <Nav.Link href="#balance_sheet">Balance Sheet</Nav.Link>
//                                 <Nav.Link href="#banks">Banks</Nav.Link>
//                             </Nav>
//                         </Accordion.Collapse>
//                     </Card>
//                     <Card>
//                         <Card.Header>
//                             <Accordion.Toggle as={Button} variant="link" eventKey="2">
//                                 Projects Progress
//                             </Accordion.Toggle>
//                         </Card.Header>
//                         <Accordion.Collapse eventKey="2">
//                             <Nav className="flex-column">
//                                 <Nav.Link href="#project_home">Find Home</Nav.Link>
//                                 <Nav.Link href="#project_jobs">Jobs, Interview</Nav.Link>
//                                 <Nav.Link href="#stream">Stream</Nav.Link>
//                                 <Nav.Link href="#activity">Activity</Nav.Link>
//                             </Nav>
//                         </Accordion.Collapse>
//                     </Card>
//                     <Card>
//                         <Card.Header>
//                             <Accordion.Toggle as={Button} variant="link" eventKey="4">
//                                 Documents
//                             </Accordion.Toggle>
//                         </Card.Header>
//                         <Accordion.Collapse eventKey="4">
//                             <Nav className="flex-column">
//                                 <Nav.Link href="#docs">Storage</Nav.Link>
//                                 <Nav.Link href="#documents_by_streams">Documents by Streams</Nav.Link>
//                                 <Nav.Link href="#imports">Imports</Nav.Link>
//                             </Nav>
//                         </Accordion.Collapse>
//                     </Card>
//                 </Accordion>