import React, {useEffect} from 'react';
import {Logger} from 'react-logger-lib';
import {useDispatch, useSelector} from 'react-redux';
import {getHosts} from '../features/docs/hostsSlice';
import {objIsNotEmpy} from '../lib/utils';
import {Host} from './storage/Hosts';

localStorage.setItem('App.Docs', 'INFO'); //WARN INFO TRACE

// https://colorhunt.co/palette/265235
// class Docs extends Component {
const COLOR_1 = '#440a67';
const COLOR_2 = '#93329e';
const COLOR_3 = '#b4aee8';
const COLOR_4 = '#ffe3fe';

const DOC_STYLE = {
    color: COLOR_2,
    backgroundColor: COLOR_4,
    borderStyle: 'solid',
    borderRadius: '6px',
    borderColor: COLOR_2,
};

const ITEM_STYLE = {
    color: COLOR_1,
    backgroundColor: COLOR_4,
};

const Docs = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        Logger.of('App.Docs.useEffect').info();
        dispatch(getHosts());
    }, []);

    const createDocs = (data, tab = 0) => {
        return Object.entries(data).map(value => {
                let key = value[0];
                let docs = value[1];
                let docs_is_object = typeof (docs) === 'object';
                let padding = tab + 'px';
                // let style={...Docs.DOC_STYLE, paddingLeft:padding};
                return (
                    <div style={{...Docs.DOC_STYLE, paddingLeft: padding}}>
                        <a href={key}>{key}</a>
                        {docs_is_object ?
                            this.createDocs(docs, tab + 10) :
                            <div style={{...Docs.ITEM_STYLE, paddingLeft: padding}}>{docs}</div>
                        }
                    </div>
                );
            },
        );
    };

    const hosts = useSelector((state) => {
        return state.hosts.entities;
    });
    // const {hosts: hostsNorm, properties: propertiesNorm, items: itemsNorm} = this.props;
    // const {entities: hosts} = hostsNorm;
    // const {entities: properties} = propertiesNorm;
    // const {entities: items} = itemsNorm;
    // const docs = this.createDocs(DOCS);
    if (!objIsNotEmpy(hosts)) {
        return (<h3>No Hosts</h3>);
    }
    return (
        <div>
            <h3>Hosts</h3>
            {Object.entries(hosts).map((value, index) => {
                return (<Host host={value[1]}/>);
            })}
        </div>
    );

};

// const mapStateToProps = (state) => {
//     Logger.of('App.Docs.mapStateToProps').info('state', state);
//     const {hosts: hostsState, docs: docsState} = state;
//     const {hosts, properties, items} = hostsState;
//     // const {entities: docs} = docsState;
//     return {hosts, properties, items};
// };

// export default connect(mapStateToProps)(Docs);
export default Docs;
