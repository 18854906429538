import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {movePlanned} from "../events/eventsSlice";
import {Logger} from "react-logger-lib";
import {getPlanned} from "../planned/plannedSlice";

localStorage.setItem('App.plannedPositionsSlice', 'INFO'); //WARN INFO TRACE

export const plannedPositionsAdapter = createEntityAdapter();
const plannedPositionsInitialState = plannedPositionsAdapter.getInitialState({status: null});

const plannedPositionsSlice = createSlice({
    name: 'positions',
    initialState: plannedPositionsInitialState,
    reducers: {
    },
    extraReducers: {
        [getPlanned.fulfilled]: (state, {payload}) => {
            Logger.of('App.plannedPositionsSlice.getPlanned.fulfilled').info('payload.positions', payload.positions);
            if (payload.positions) {
                plannedPositionsAdapter.upsertMany(state, payload.positions);
            }
        },
        [movePlanned.fulfilled]: (state, {payload}) => {
            plannedPositionsAdapter.upsertOne(state, payload);
        },
    },
})

export default plannedPositionsSlice.reducer;
