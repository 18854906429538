import * as React from 'react';

// import { LayoutManager } from 'plexus';
// TODO(joe): Update import after killing `DirectedGraph`
// import Digraph from 'plexus/Digraph';
import {Logger} from "react-logger-lib";
import Graph from "react-graph-vis";
// import LayoutManager from "@jaegertracing/plexus/lib/LayoutManager";

// localStorage.setItem('App.Graph', 'WARN'); //WARN INFO TRACE


const graph = {
    nodes: [
        // { id: 'web', group: 'group_have', label: 'web-app : login' },
        // { id: 'users', group: 'group_have', label: 'user-store : get-user' },
        // { id: 'cache', group: 'group_have', label: 'cache : get' },
        // { id: 'db', group: 'group_have', label: 'db : get-user' },
        // { id: 'auth', group: 'group_have', label: 'auth : login' },
        // { id: 'auth1', group: 'group_have', label: 'auth : login' },

        { id: 'h1_job_ak', group: 'group_have', label: 'Job AK' },
        { id: 'h1_car', group: 'group_have', label: 'Car Rent' },
        { id: 'h1_home', group: 'group_have', label: 'Home Rent' },

        { id: 'h2_fin_cache', group: 'group_have', label: 'Cache' },
        { id: 'h2_fin_401k', group: 'group_have', label: '401k' },
        
        { id: 'h3_kn_languages', group: 'group_have', label: 'languages' },
        { id: 'h3_kn_frameworks', group: 'group_have', label: 'Frameworks' },

        { id: 'd1_job_salary', group: 'group_doing', label: 'Salary' },
        { id: 'd1_job_knowledge', group: 'group_doing', label: 'Knowledge' },
        
        { id: 'd2_udemy', group: 'group_doing', label: 'Udemy' },
        { id: 'd2_reading', group: 'group_doing', label: 'Reading' },

        { id: 'd3_coding', group: 'group_doing', label: 'Coding Daily\nProblems' },
        { id: 'd3_site', group: 'group_doing', label: 'Site' },
        
        { id: 'w11_job_google', group: 'group_want', label: 'Google' },
        { id: 'w21_job_dj', group: 'group_want', label: 'DJ' },
        { id: 'w23_job_ak', group: 'group_want', label: 'AK' },

        { id: 'w21_fin_401k', group: 'group_want', label: '401k' },
        { id: 'w22_fin_assets', group: 'group_want', label: 'Assets' },
        { id: 'w23_fin_stock', group: 'group_want', label: 'Stock"' },
        { id: 'w24_fin_cache', group: 'group_want', label: 'Cache' },
        // { id: 'w11_job_google', group: 'group_have', label: 'Google' },
        // { id: 'w21_job_dj', group: 'group_have', label: 'DJ' },
        // { id: 'w23_job_ak', group: 'group_have', label: 'AK' },

        { id: 'w31_home_brigton', group: 'group_want', label: 'Brigton' },
        { id: 'w32_home_natic', group: 'group_want', label: 'Natic' },
        { id: 'w33_home_lexington', group: 'group_want', label: 'Lexington' },
        { id: 'w34_home_allston', group: 'group_want', label: 'Allston' },

        { id: 'ext1_800k', group: 'group_want', label: '$800k' },
        { id: 'ext2_900k', group: 'group_want', label: '$900k' },
        { id: 'ext3_2_6pc', group: 'group_want', label: '2.6%/year' },
        { id: 'ext3_8pc', group: 'group_want', label: '8%/year' },
        { id: 'ext4_10pc', group: 'group_want', label: '10%/year' },
        { id: 'ext5_requirements', group: 'group_want', label: 'Job\nrequirements' },
        { id: 'w4_car', group: 'group_want', label: 'Car' },
    ],
// Edges must refer to the `id` field of vertices.
    edges: [
        { from: 'h1_job_ak', to: 'd1_job_salary' },
        { from: 'h1_job_ak', to: 'd1_job_knowledge' },

        { from: 'd1_job_knowledge', to: 'ext5_requirements' },
        { from: 'd2_udemy', to: 'ext5_requirements' },
        { from: 'd3_coding', to: 'ext5_requirements' },

        { from: 'ext5_requirements', to: 'w11_job_google' },
        { from: 'ext5_requirements', to: 'w23_job_ak' },
        { from: 'ext5_requirements', to: 'w21_job_dj' },

        { from: 'd3_site', to: 'w21_job_dj' },

        { from: 'h2_fin_cache', to: 'ext3_2_6pc' },
        { from: 'h2_fin_401k', to: 'ext3_2_6pc' },

        { from: 'd1_job_salary', to: 'ext3_2_6pc' },

        { from: 'ext3_2_6pc', to: 'ext1_800k' },
        { from: 'ext1_800k', to: 'w31_home_brigton' },


        { from: 'ext3_2_6pc', to: 'ext2_900k' },
        { from: 'ext2_900k', to: 'w32_home_natic' },

        { from: 'ext3_2_6pc', to: 'ext2_900k' },
        { from: 'ext2_900k', to: 'w33_home_lexington' },

        { from: 'd1_job_salary', to: 'ext3_8pc' },
        { from: 'ext3_8pc', to: 'w22_fin_assets' },

        { from: 'd1_job_salary', to: 'ext3_8pc' },
        { from: 'ext3_8pc', to: 'w23_fin_stock' },

        { from: 'd1_job_salary', to: 'ext4_10pc' },
        { from: 'ext4_10pc', to: 'w21_fin_401k' },

        { from: 'h1_home', to: 'w34_home_allston' },
        { from: 'h1_car', to: 'w4_car' },

        // { from: 'web', to: 'users' },
        // { from: 'web', to: 'auth' },
        // { from: 'users', to: 'cache' },
        // { from: 'users', to: 'db' },
    ]
}

const options ={
    layout: {
        hierarchical: true
    },
    nodes: {
        shape: "box",
    },
    height: "800px",
    // height: "100%",
    groups: {
        group_have: {color:{background:'green'}, borderWidth:3},
        group_doing: {color:{background:'blue'}, borderWidth:3},
        group_want: {color:{background:'red'}, borderWidth:3}
    }
};

const events = {
    select: function(event) {
        // var {nodes, edges} = event;
    }
};

class GraphComponent extends React.Component {

    render() {
        Logger.of('App.GraphComponent.render').info();
        return ( 
            <Graph
                graph={graph}
                options={options}
                events={events}
            />
        )
    }
}

export default GraphComponent;
