import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Logger} from "react-logger-lib";

localStorage.setItem('App.AddTagModal', 'WARN'); //WARN INFO TRACE

class AddTagModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        Logger.of('App.AddTagModal.AddTagModal').info('ctor', 'props', this.props);
        this.state.add_tag = props.show;
        this.onAddTagShow = this.onAddTagShow.bind(this);
        this.onAddTagClose = this.onAddTagClose.bind(this);
    }

    onAddTagShow() {
        console.log('onAddTagShow');
        this.setState({add_tag: true});
        return;
    }

    onAddTagClose() {
        console.log('onAddTagClose');
        this.setState({add_tag: false});
        return;
    }

    render() {
        const {add_tag} = this.state;
        return (
            <Modal show={add_tag} onHide={this.onAddTagClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Modal body text goes here.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary">Close</Button>
                    <Button variant="primary">Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AddTagModal;
