import {client} from "../../api/client";
import {API_AUTH} from "../../constants";

const googleLogin = async (accesstoken) => {
    // let res = await axios.post(
    let res = await client.post(
      API_AUTH,
      {
        access_token: accesstoken,
      }
    );
    console.log(res);
    return await res.status;
  };

export default googleLogin;
