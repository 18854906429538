import {combineReducers} from "@reduxjs/toolkit";
import rangeReducer from "../features/ranges/rangesSlice";
import eventsReducer from "../features/events/eventsSlice";
import plannedReducer from "../features/planned/plannedSlice";
import eventPositionsReducer from "../features/positions/eventPositionsSlice";
import plannedPositionsReducer from "../features/positions/plannedPositionsSlice";
import layoutsReducer from "../features/layouts/layoutsSlice";
import activitiesReducer from "../features/activities/activitiesSlice";
import streamsReducer from "../features/streams/streamsSlice";
import hostsReducer from "../features/docs/hostsSlice";
import dirsReducer from "../features/docs/dirsSlice";
import filesReducer from "../features/docs/filesSlice";
import entitiesReducer from "../features/entities/entitiesSlice";
import {connectRouter} from "connected-react-router";

const createRootReducer = (history) => combineReducers({
    ranges: rangeReducer,
    streams: streamsReducer,
    activities: activitiesReducer,
    events: eventsReducer,
    planned: plannedReducer,
    eventPositions: eventPositionsReducer,
    plannedPositions: plannedPositionsReducer,
    layouts: layoutsReducer,
    hosts: hostsReducer,
    dirs: dirsReducer,
    files: filesReducer,
    entities: entitiesReducer,
    router: connectRouter(history),  // https://github.com/supasate/connected-react-router#step-1
});

export default createRootReducer;
