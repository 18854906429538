import {createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {getEvents, moveEvent} from "../events/eventsSlice";
import {Logger} from "react-logger-lib";

localStorage.setItem('App.eventPositionsSlice', 'INFO'); //WARN INFO TRACE

export const eventPositionsAdapter = createEntityAdapter();
const eventPositionsInitialState = eventPositionsAdapter.getInitialState({status: null});

const eventPositionsSlice = createSlice({
    name: 'positions',
    initialState: eventPositionsInitialState,
    reducers: {
    },
    extraReducers: {
        [getEvents.fulfilled]: (state, {payload}) => {
            Logger.of('App.eventPositionsSlice.getEvents.fulfilled').info('payload.positions', payload.positions);
            if (payload.positions) {
                eventPositionsAdapter.upsertMany(state, payload.positions);
            }
        },
        [moveEvent.fulfilled]: (state, {payload}) => {
            eventPositionsAdapter.upsertOne(state, payload);
        },
    },
})

export default eventPositionsSlice.reducer;
