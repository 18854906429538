import moment from "moment";
import {
    API_ENTITIES_URL,
    API_EVENTS_URL,
    API_PLANNED_URL,
    API_STORAGE_DIRECTORIES_URL, API_STORAGE_FILES_URL,
    API_STORAGE_HOSTS_URL,
    MAIN,
    YYYY_MM_DD,
    YYYYMMDDHHmmss,
} from '../constants';


export function objIsNotEmpy(obj) {
    return typeof obj != "undefined" && obj && Object.keys(obj).length > 0
}


export function arrIsNotEmpty(data) {
    return data && data.length;
}

export function getTime(obj) {
    return moment(obj)
    // return moment.utc(obj)
}
export function getToday(day=undefined) {
    let start_day = getTime(day)
    let end_day = start_day.clone().add(1, "day")
    return {start: start_day.format(YYYY_MM_DD), end: end_day.format(YYYY_MM_DD)}
}

export function getCurrentDateTime(day=undefined) {
    let start_day = getTime(day)
    return start_day.format(YYYYMMDDHHmmss)
}


export function shiftRange(range, shift) {
    const {start, end} = range;

    let start_day = getTime(start).add(shift, "day")
    let end_day = getTime(end).add(shift, "day")
    return {start: start_day.format(YYYY_MM_DD), end: end_day.format(YYYY_MM_DD)}
}


export function getStorageURL(id) {
    let url = API_STORAGE_HOSTS_URL;
    if (id) {
        url = url.concat(id, '/')
    }
    // console.log(url)
    return url;
}

export function getStorageItemURL(host, path='') {
    let url = API_STORAGE_DIRECTORIES_URL
        .replace('${host}', host)
        .replace('${path}', path);
    // console.log(url)
    return url;
}
export function getStorageFilesURL(host, path='') {
    let url = API_STORAGE_FILES_URL
        .replace('${host}', host)
        .replace('${path}', path);
    // console.log(url)
    return url;
}

export function getPlannedURL(data={}) {
    // const {stream, ranges, activities} = data;
    // let url = API_PLANNED_URL;
    // console.log(url)
    // return url;
    return API_PLANNED_URL;
}


export function getEventURL(data={}) {
    const {stream, ranges, activities} = data;
    let url = API_EVENTS_URL;

    if(activities) {
        url = url.concat('activities/');
    } else {
        if (stream && stream !== MAIN) {
            url = url.concat('stream/', stream.id, '/');
        }
        if (ranges) {
            const {start, end} = ranges;
            url = url.concat(start, '/');
        }
    }

    // console.log(url)
    return url;
}

export function getEntitiesURL(data={}) {
    // const {type} = data;
    // let url = API_ENTITIES_URL;
    // // console.log(url)
    return API_ENTITIES_URL;
}

export const arrayToIdMap = (data, field = 'id') => {
    let new_data = {};
    Object.entries(data).forEach((value, index) => {
        // if (value[1].positions && value[1].positions.length > 0) {
        //     value[1].position = value[1].positions[0];
        // }
        let ind = value[1][field];
        new_data[ind] = value[1];
    });

    return new_data;
}
function _find_stream_position(positions, stream) {
    let stream_position = Object.entries(positions).find((value1, index1) => {
        return (value1[1].stream === stream.id);
    })
    if (stream_position) {
        return stream_position[1];
    }

    return stream_position;
}
export function _list_to_dict(data) {
    let entities = {}
    Object.entries(data).forEach(value => {
            entities[value[1].id] = value[1];
        }
    );
    return entities;
}
export function _list_events_to_dict(events, stream) {

    // events.sort( (a, b) => {
    //     let aa = a;
    //     let bb = b;
    //     let r = moment(a.start) < moment(b.start);
    //     return r ? 1: -1;
    // })

    let entities = {}
    Object.entries(events).forEach((value, index) => {
        let event = value[1];
        const {positions} = event;
        let stream_position = _find_stream_position(positions, stream);
        //  Object.entries(positions).find( (value1, index1) => {
        //     return (value1[1].stream == stream.id);
        // })
        if (stream_position) {
            value[1].position = stream_position;
        }
        entities[value[1].id] = value[1];
    });
    return entities;
}