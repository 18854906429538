import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Logger} from "react-logger-lib";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import {HOUR_HEIGHT_PX, MAIN, VERTICAL} from "../constants";
import {createEvents, createEventsHTML, getRangeHours} from "../lib/utils_ui";
import EventModal from "./EventModal";
import {getTime, getToday, objIsNotEmpy, shiftRange} from "../lib/utils";
import {getEventsForToday} from "../features/events/eventsSlice";
import {STYLE_CALENDAR_TABLE} from "../style";
import {Col, Container, FormCheck, ListGroup, ListGroupItem, ProgressBar, Row, ToggleButton} from "react-bootstrap";
import {selectActivities} from "../features/activities/activitiesSlice";


localStorage.setItem('App.Today', 'WARN'); //WARN INFO TRACE


class Today extends Component {
    state = {};

    static propsTypes = {
        test_id: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.state.add_note = false;
        // this.state.new_event_pos = {x: 20, y: 20};

        this.onRangeShift = this.onRangeShift.bind(this);
        this.onRangeChange = this.onRangeChange.bind(this);

        this.onAddNoteShow = this.onAddNoteShow.bind(this);
        // this.onEventPositionCallback = this.onEventPositionCallback.bind(this);
        // this.onAddNoteClose = this.onAddNoteClose.bind(this);
    }

    componentDidMount() {
        Logger.of('App.Today.componentDidMount').info();
        const {ranges} = this.props;
        this.props.dispatch(getEventsForToday({stream: MAIN, ranges}));
    }

    // onEventPositionCallback(event) {
    //     Logger.of('App.Today.onEventPositionCallback').info();
    //     let {new_event_pos} = this.state;
    //     this.setState(state => {
    //         return {
    //             new_event_pos: {x: state.new_event_pos.x+20, y: state.new_event_pos.y+20}
    //         }
    //     })
    //     return new_event_pos;
    // }

    onAddNoteShow() {
        console.log('onAddNoteShow');
        this.setState({add_note: true});
    }

    onRangeChange() {
        let el_range_start = document.getElementById("today_range_start");
        Logger.of('App.Today.onRangeChange').info('el_range_start', el_range_start);

        const {selected_stream} = this.props;
        let new_ranges = getToday(el_range_start.value);
        this.props.dispatch(getEventsForToday({stream: selected_stream, ranges: new_ranges}));
    }

    onRangeShift(shift) {
        const {selected_stream, ranges} = this.props;
        Logger.of('App.Today.onRangeShift').info('shift', shift, 'ranges', ranges);
        let new_ranges = shiftRange(ranges, shift);
        this.props.dispatch(getEventsForToday({stream: selected_stream, ranges: new_ranges}));
    }

    getButtonsBar() {
        const {ranges} = this.props;
        const {start: start_ranges, end: end_ranges} = ranges;
        console.log('getButtonsBar ranges', start_ranges);
        let checked = true;
        return (
            <Container aria-label="Calendar" size="sm">
                <h4>{start_ranges} - {end_ranges}</h4>
                <Button id="today_button_prev" onClick={this.onRangeShift.bind(this, -1)} size="sm">Prev</Button>
                <input id="today_range_start" type="date" value={start_ranges}
                       onChange={this.onRangeChange.bind(this)}/>
                <Button id="today_button_next" onClick={this.onRangeShift.bind(this, 1)} size="sm">Next</Button>
                <Button id="today_button_add" onClick={this.onAddNoteShow} size="sm">Add</Button>
                <ToggleButton type="checkbox" checked={checked} size="sm" value={true}>Google</ToggleButton>
            </Container>
        )
    }

// {/*<Button id="cal_button_clean" onClick={this.onClean}>Clean</Button>*/}
// {/*<Button id="cal_button_dir" onClick={this.onChangeDirection}>Dir</Button>*/}


    render() {
        Logger.of('App.Today.render').info();
        let hour_height = HOUR_HEIGHT_PX
        let dir = VERTICAL
        let current_hour = getTime().hour();
        const {events = {}, planned = {}, ranges = {}, activities} = this.props;
        const {add_note} = this.state;

        let loaded = objIsNotEmpy(events);
        // let prop = {range_start: ranges.start, range_end: ranges.end};

        // let thisHTMLElement = findDOMNode(this);


        let components_events = createEvents(events, MAIN);
        let components_events_html = createEventsHTML(events, MAIN, activities);

        // let components_planned = createPlanned(planned, MAIN);
        let components_planned_html = createEventsHTML(planned, MAIN, activities);

        return (
            <div id="today_component_id">
                <Container fluid>
                    <Row>
                        <Col md={7}>
                            <div id="calendar_header">
                                {this.getButtonsBar()}
                            </div>
                            <Table id="calendar_table" striped bordered style={STYLE_CALENDAR_TABLE}>
                                <tbody>
                                {getRangeHours(ranges.start, ranges.end, hour_height, dir, current_hour)}
                                </tbody>
                            </Table>
                            <div id="today_events_div_id">
                                {loaded ? components_events : <h4>No Data</h4>}
                            </div>
                            {/*<div id="today_planned_div_id">*/}
                            {/*    {loaded ? components_planned : <h4>No Plan</h4>}*/}
                            {/*</div>*/}
                            <EventModal show={add_note}/>
                            {/*{this.getAddNote()}*/}
                        </Col>
                        <Col md={5}>
                            <h4>Summary for Day:</h4>
                            <ListGroup>
                                <ListGroupItem>NL
                                    <ProgressBar variant="success" now={50} label={`${50}%`}/>
                                </ListGroupItem>
                                <ListGroupItem>DL
                                    <ProgressBar variant="warning"  now={30} label={`${30}%`}/>
                                </ListGroupItem>
                                <ListGroupItem>NR
                                    <ProgressBar variant="danger" now={10} label={`${10}%`}/>
                                </ListGroupItem>
                                <ListGroupItem>Leaning:
                                    <ProgressBar variant="info" now={50} label={`${50}%`}/>
                                </ListGroupItem>
                                <ListGroupItem>Reading:
                                    <ProgressBar variant="info" now={25} label={`${25}%`}/>
                                </ListGroupItem>
                            </ListGroup>
                            <br/>
                            <h4>Tasks:</h4>
                            <FormCheck label="Review calendar" defaultChecked={true}/>
                            <FormCheck label="Submit UI"/>
                            <br/>
                            <h4>List of Events:</h4>
                            {loaded ? components_events_html : <h4>No Data</h4>}
                            <br/>
                            <h4>Plan:</h4>
                            {loaded ? components_planned_html : <h4>No Data</h4>}
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.Today.mapStateToProps').info('state', state);
    const {streams: streamsState, events: eventsState, planned: plannedState, layouts: layoutsState, ranges} = state;
    const {selected_stream = MAIN} = streamsState;
    const {entities: events} = eventsState;
    const {entities: planned} = plannedState;
    const {entities: layouts} = layoutsState;

    const activities = selectActivities(state);

    return {events, planned, layouts, ranges, selected_stream, activities};
};

export default connect(mapStateToProps,)(Today);
