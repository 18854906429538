import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {getHostSelectedDirectories} from '../../features/docs/dirsSlice';
import useCollapsedItems from '../../hooks/collapsed';
import {STYLE_HOST} from '../../style';
import {Directories} from './Directories';

export const Host = (props) => {
    const [directories, setDirectories] = useState({});
    const dispatch = useDispatch();

    const {host, style = STYLE_HOST} = props;
    const {id, host: alias, user, ip, name, root} = host;

    let tags = {tag: 'tag1'};

    const hostDirectoriesItems = <Directories host={alias} root={root} directories={directories}/>;
    const loadItemsOnOpen = (host, setOpenItems) => {
        console.log('Call getDirectories from HOST');
        dispatch(getHostSelectedDirectories({host, dir: ''}))
            .then((res) => {
                if (res && res.payload) {
                    setDirectories(res.payload.dirs);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const [ButtonCollapseDirectories, CollapsedDirectories] = useCollapsedItems(
        {
            id: alias,
            components: hostDirectoriesItems,
            loadItemsOnOpen,
            isItemCollapsedInitState: false,
        },
    );

    // const hostPropertiesItems = <Properties properties={host}/>
    // const [buttonHostProps, collapsedHostProps] = useCollapsedItems( {id, items: hostPropertiesItems});

    return (
        <div style={style}>
            {ButtonCollapseDirectories}
            {/*{buttonHostProps}*/}
            <><strong>{alias}</strong> {user}@{name} [{ip}]</>
            {/*<Tags data={{tags}}/>*/}
            {/*{collapsedHostProps}*/}
            {CollapsedDirectories}
        </div>
    );
};
