import {connect} from "react-redux";
import EventBase from "./EventBase";
import {Logger} from "react-logger-lib";
import {movePlanned} from "../features/events/eventsSlice";
import {getUnit} from "../lib/utils_ui";
import {selectRanges} from "../features/ranges/rangesSlice";
import {selectLayouts, selectPlanned} from "../features/planned/plannedSlice";
import {selectActivities} from "../features/activities/activitiesSlice";


localStorage.setItem('App.Planned', 'INFO'); //WARN INFO TRACE


class Planned extends EventBase {

    constructor(props) {
        super(props);
        Logger.of('App.Planned.ctor').info('props', this.props);
    }

    savePosition(position) {
        position = {planned: position.event, ...position};
        this.props.dispatch(movePlanned(position));
    }

    getCardStyle(event) {
       Logger.of('App.Planned.getEventStyle').trace('data', event);
       let style = super.getCardStyle(event);
       return {...style, borderColor: '#1f0dbd', borderWidth: getUnit(3), width: getUnit(350)};
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.Planned.mapStateToProps').info('state', state);
    const activities = selectActivities(state);
    const ranges = selectRanges(state);
    const planned = selectPlanned(state);
    const layouts = selectLayouts(state);
    return {ranges, events: planned, layouts, activities};

    // // const {planned: plannedState} = state;
    // // use planned as events
    // const {planned: eventsState, plannedPositions: plannedPositionsState, streams: streamsState, activities: activitiesState} = state;
    // const {entities: tags, selected_stream} = streamsState;
    // const {entities: events} = eventsState;
    // const {entities: positions} = plannedPositionsState;
    // // const positions = [];
    // const {entities: activities} = activitiesState;
    // return {events, positions, activities, tags, selected_stream};
};


export default connect(mapStateToProps)(Planned);
