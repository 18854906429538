import React, {Component} from "react";
import {HHmm, YYYY_MM_DD, YYYYMMDDHHmmss} from "../constants";
import Card from "react-bootstrap/Card";
import {Container, FormLabel} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {connect} from "react-redux";
import {Logger} from "react-logger-lib";
import {postEvent} from "../features/events/eventsSlice";
import {getTime, objIsNotEmpy} from "../lib/utils";

const HEALTH = [
    'Probiotic',
    'Vitamin D',
    'Allopurinol',
    'Colchicine',
]


class ActivityHealth extends Component {

    constructor(props) {
        super(props);
        Logger.of('App.ActivityHealth.ctor').info();
        this.onSubmitActivityHealth = this.onSubmitActivityHealth.bind(this);

    }

    onSubmitActivityHealth(element) {
        Logger.of('App.ActivityHealth.onSubmitActivityHealth').info();
        const date_time_now = getTime();
        let activity_id = this.getHealthID();
        let event = {
            start: date_time_now.format(YYYYMMDDHHmmss),
            activity_id,
            note: element,
            // tags: []
        };

        this.props.dispatch(postEvent(event));
    }

    getHealthID() {
        const {activities} = this.props;
        let activity = Object.entries(activities).find( (value)=>{
            return value[1].name === 'Health'
        })

        console.assert(objIsNotEmpy(activity), 'Health activity must be in the data set')

        return activity[1].id;  // undefined;
    }

    createHealthComponent(element, time_start, stats) {
        let component_id = 'activity_health_'.concat(element)
        return (
            <Row>
                <Col xs={2}>
                    <FormLabel>{stats}</FormLabel>
                </Col>
                <Col xs={1}>
                    <FormLabel>{time_start}</FormLabel>
                </Col>
                <Col xs={1}>
                    <Button id={component_id} onClick={this.onSubmitActivityHealth.bind(null, element)}>{element}</Button>
                </Col>
            </Row>
        )
    }

    render() {
        const date_time_now = getTime();
        let date_start = date_time_now.format(YYYY_MM_DD);
        let time_start = date_time_now.format(HHmm);

        const {stats = ''.concat(date_start, ' ', time_start)} = this.props;

        let components = Object.entries(HEALTH).map( (value, index) => {
            return this.createHealthComponent(value[1], time_start, stats) ;
        })

        return (
            <Card.Body>
                <h5>My mood now, activity, pills</h5>
                <Container fluid>
                    {components}
                </Container>
            </Card.Body>
        )
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.ActivityHealth.mapStateToProps').info('state', state);
    const {activities: activitiesState} = state;
    const {entities: activities, stats} = activitiesState;
    return {activities, stats};
};

export default connect(mapStateToProps,)(ActivityHealth);

