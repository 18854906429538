import {combineReducers, createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";

import {Logger} from "react-logger-lib";
import {client} from "../../api/client";
import {getEntitiesURL} from "../../lib/utils";
import {normalize} from "normalizr";
import {entitiesSchema} from "../../app/schemas";

export const entitiesAdapter = createEntityAdapter();
export const entitiesInitialState = entitiesAdapter.getInitialState({status: null, error: null});

export const propertiesAdapter = createEntityAdapter();
export const propertiesInitialState = propertiesAdapter.getInitialState();

export const getEntities = createAsyncThunk(
    'entities/getEntities',
    async (data, {dispatch, getState}) => {
        Logger.of('App.entitiesSlice.getHosts').info('data', data);
        // const {stream, ranges} = data;
        let url = getEntitiesURL(data);
        let events = await client.get(url).then(
            (res) => res
        )
        const normalized = normalize(events, entitiesSchema)
        return normalized.entities
    }
)

const entitiesSlice = createSlice({
    name: 'entities',
    initialState: entitiesInitialState,
    reducers: {},
    extraReducers: {
        [getEntities.pending]: (state, action) => {
            console.log();
            state.error = null;
            state.status = 'loading'
        },
        [getEntities.fulfilled]: (state, {payload}) => {
            Logger.of('App.entitiesSlice.getEntities.fulfilled').info('payload', payload);
            if (payload.entities) {
                entitiesAdapter.setAll(state, payload.entities);
            } else {
                entitiesAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success'
        },
        [getEntities.rejected]: (state, payload) => {
            Logger.of('App.entitiesSlice.getEntities.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed'
        }
    }
})

const propertiesSlice = createSlice({
    name: 'properties',
    initialState: propertiesInitialState,
    reducers: {},
    extraReducers: {
        [getEntities.fulfilled]: (state, {payload}) => {
            Logger.of('App.propertiesSlice.getEntities.fulfilled').info('payload', payload);
            if (payload.properties) {
                propertiesAdapter.setAll(state, payload.properties);
            } else {
                propertiesAdapter.removeAll(state);
            }
        },
    }
})

const reducer = combineReducers({
    entities: entitiesSlice.reducer,
    properties: propertiesSlice.reducer,
})


export const {
    pending: getEntitiesStart,
    fulfilled: getEntitiesDone,
    rejected: getEntitiesFailed
} = getEntities;

// export const {
//     getEntities
// } = entitiesSlice.actions;

export const {
    selectAll: selectAllEntities,
    selectById: selectEntitiesById,
    selectIds: selectEntitiesIds
} = entitiesAdapter.getSelectors(state => state.entities.entities)

export const {
    selectAll: selectAllProperties,
    selectById: selectPropertiesById,
    selectIds: selectPropertiesIds,
    selectEntities: selectPropertiesEntities,
} = propertiesAdapter.getSelectors(state => state.entities.properties)

export default reducer;
// export default entitiesSlice.reducer;
