import {configureStore} from '@reduxjs/toolkit'
import {routerMiddleware} from 'connected-react-router'
import {createBrowserHistory} from 'history';
import {compose} from 'redux'
import createRootReducer from "./reducers";


// https://github.com/supasate/connected-react-router#step-2
export const history = createBrowserHistory();
// export const history = createHashHistory();


export default function configureStoreMy(preloadedState) {
  const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    const store =  configureStore({
        reducer: createRootReducer(history),
        preloadedState,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
            .concat(routerMiddleware(history)),
    });

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            store.replaceReducer(createRootReducer(history));
        });
    }

    return store
}
