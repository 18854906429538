import React from 'react'
import {STYLE_ERROR} from "../style";



const NoMatch = () => (
  <h1 style={STYLE_ERROR}>
    Not Implemented
  </h1>
)

export default NoMatch
