import {createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Logger} from "react-logger-lib";
import {layoutEventsByDayTime} from "../../lib/utils_layout";
import {normalize} from "normalizr";
import {layoutsSchema} from "../../app/schemas";


export const layoutsAdapter = createEntityAdapter();
export const layoutsInitialState = layoutsAdapter.getInitialState({status: null, error: null});


export const calculateLayout = createAsyncThunk(
    'layouts/calculateLayout',
    async (data, {dispatch, getState}) => {
        Logger.of('App.layoutsSlice.calculateLayout').info('data', data);
        let {events, ranges} = data;
        let positions = layoutEventsByDayTime(events, {ranges});
        // let positions = layoutChildren(events, {ranges});
        const normalized = normalize(positions, layoutsSchema);
        return normalized.entities;
    }
)


const layoutsSlice = createSlice({
    name: 'layouts',
    initialState: layoutsInitialState,
    reducers: {
    },
    extraReducers: {
        [calculateLayout.pending]: (state, action) => {
            console.log();
            state.error = null;
            state.status = 'calculating'
        },
        [calculateLayout.fulfilled]: (state, {payload}) => {
            Logger.of('App.layoutsSlice.calculateLayout.fulfilled').info('payload.events', payload.events);
            if (payload.layouts) {
                layoutsAdapter.setAll(state, payload.layouts)
            } else {
                layoutsAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success'
        },
        [calculateLayout.rejected]: (state, payload) => {
            Logger.of('App.layoutsSlice.calculateLayout.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed'
        }
    }
})

export const selectLayout = state => state.layouts.layout;

export const {actions} = layoutsSlice.actions;
export const {pending: calculateLayoutStart,
              fulfilled: calculateLayoutDone,
              rejected: calculateLayoutFailed} = calculateLayout;

export default layoutsSlice.reducer;
