import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {Logger} from 'react-logger-lib';
import {client} from '../../api/client';
import {dirsSchema} from '../../app/schemas';
import {getStorageItemURL, getStorageURL} from '../../lib/utils';

export const itemsAdapter = createEntityAdapter();
export const itemsInitialState = itemsAdapter.getInitialState();

// const USE_JSON = true;
const USE_JSON = false;

/**
 * Return directory's properties and subdirectories
 *
 */
export const getHostSelectedDirectories = createAsyncThunk(
    'dirs/getHostSelectedDirectories',
    async (data, {dispatch, getState}) => {
        const {host, dir} = data;
        let url = getStorageURL(host, dir);
        let items = await client.get(url).then(
            (res) => res,
        );
        const normalized = normalize(items.dirs, dirsSchema);
        return normalized.entities;
    },
);

/**
 * Return directory's properties and subdirectories
 *
 */
export const getDirectories = createAsyncThunk(
    'dirs/getDirectories',
    async (data, {dispatch, getState}) => {
        const {host, dir} = data;
        let url = getStorageItemURL(host, dir);
        let items = await client.get(url).then(
            (res) => res,
        );
        const normalized = normalize(items.dir.dirs, dirsSchema);
        return normalized.entities;
    },
);

const dirsSlice = createSlice({
    name: 'dirs',
    initialState: itemsInitialState,
    reducers: {},
    extraReducers: {
        [getDirectories.pending]: (state, action) => {
            console.log();
            state.error = null;
            state.status = 'loading';
        },
        [getDirectories.fulfilled]: (state, {payload}) => {
            Logger.of('App.docsSlice.getDirectories.fulfilled').info('payload', payload);
            if (payload.dirs) {
                // itemsAdapter.addOne(state, payload.items)  // add as undefined, not id
                // itemsAdapter.addMany(state, payload.items)
                // itemsAdapter.updateMany(state, payload.items)
                // itemsAdapter.updateOne(state, payload.dirs);
                itemsAdapter.setAll(state, payload.dirs);
            }
            state.error = null;
            state.status = 'success';
        },
        [getDirectories.rejected]: (state, payload) => {
            Logger.of('App.docsSlice.getDirectories.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },
    },
});


// TODO: remove, for compability only
/**
 * Find directory by name
 * @param state - items/directories object as {ids: .., entities: ...}
 * @param name
 * @returns {[string, unknown]}
 */
export const selectDirectoryByName = (state, name) => {
    return Object.values(state.entities).find((value => (value.name === name)));
};

// export const {
//     pending: getDirectoriesStart,
//     fulfilled: getDirectoriesDone,
//     rejected: getDirectoriesFailed,
// } = getDirectories;

export default dirsSlice.reducer;
