import {Col, Container, Row} from 'react-bootstrap';
import useCollapsedItems from '../../hooks/collapsed';
import Streams from '../Streams';
import style from './Stream.style';

const Stream = ({stream}) => {

    const subStreamsItems = <Streams streamsProp={stream.children} theme={style.none}/>;
    const [buttonChildren, subStreamsProps] = useCollapsedItems({
        id: 'name', components: subStreamsItems,
    });

    //         <Container style={{...style.stream, flexDirection: 'row'}}>
    return (
        <Container fluid style={style.stream}>
            <Row>
                <Col md={1}>
                    {buttonChildren}
                </Col>
                <Col md={2}>
                    <div><strong>{stream.name}</strong></div>
                </Col>
                <Col md={7}>
                    <div>{stream.description}</div>
                </Col>
            </Row>
            {stream.children &&
            subStreamsProps
            }
        </Container>
    );
};

//             {stream.children &&
//             <Streams streamsProp={stream.children} />

// Object.entries(stream.children).forEach((value, index) => {
//     const child = value[1];
//     return (
//         <Stream stream={child}/>
//     );
// });
export default Stream;
