// https://medium.com/@davidtranwd/implement-tree-view-component-with-reactjs-and-styled-components-5eea3b1603cf
import React from 'react';
import {STYLE_DIRECTORIES} from '../../style';
import Directory from './Directory';

export const Directories = (props) => {
    const {host, root, directories} = props;

    return (
        <div style={STYLE_DIRECTORIES}>
            {directories && Object.entries(directories).map((value, index) => {
                let directory = value[1];
                const {properties: directory_properties_ids = {}} = directory;
                // let directory_props = selectEntitiesByIds(all_props, directory_properties_ids);
                return (
                    <div>
                        <Directory host={host} root={directory.id} selected={directory.selected}/>
                    </div>
                );
            })}
        </div>
    );
};
