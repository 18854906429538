import React, {useEffect, useRef} from 'react';
import {Container} from 'react-bootstrap';
import {DataSet} from 'vis-data';
import {Timeline} from 'vis-timeline/standalone';
import toogle_data from '../../../src/assets/2021-08.json'

const convert = (data) => {
  return Object.entries(data).map( (value, index) => {
      const item = value[1];
      return {
          ...item,
          end: item['stop'],
          content: item['description'],
          group: item['pid']
      }
  })
};

const style = {
    container: {
        width: '100%',
        height: '90vh',
    },
    component: {
        width: '110%',
        height: '90vh',
    },
    timeline: {
        width: 5000,
        height: 300,
    },
};

// https://stackoverflow.com/questions/40792649/rendering-vis-js-Timeline-into-container-via-react-js
const VisTimeline = () => {

    const items = convert(toogle_data);
    //     new DataSet([
    //     {id: 1, content: 'item 1', start: '2021-08-20'},
    //     {id: 2, content: 'item 2', start: '2021-08-14'},
    //     {id: 3, content: 'item 3', start: '2021-08-18'},
    //     {id: 4, content: 'item 4', start: '2021-08-16', end: '2021-08-19'},
    //     {id: 5, content: 'item 5', start: '2021-08-25'},
    //     {id: 6, content: 'item 6', start: '2021-08-27'},
    // ]);
    const options = style.timeline;

    // return (
    //     <Timeline options={options}/>
    //
    // );

    const visJsRef = useRef(null);
    const timeline = useRef(null);

    useEffect(() => {
        timeline.current = new Timeline(visJsRef.current, items, options);
        // Use `Timeline` here to configure events, etc
        console.log('timeline', timeline);
    }, [visJsRef, timeline, items]);

    return (
        <Container style={style.container}>
            <div style={style.component} ref={visJsRef}/>
        </Container>
    );
};

export default VisTimeline;
