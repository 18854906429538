import React, {Component} from "react";
import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";

class EventModal extends Component {
    state = {};

    constructor(props) {
        super(props);
        this.state.add_note = props.show;
        this.onAddEventShow = this.onAddEventShow.bind(this);
        this.onAddEventClose = this.onAddEventClose.bind(this);
    }

    onAddEventShow() {
        console.log('onAddNoteShow');
        this.setState({add_note: true});
        return;
    }

    onAddEventClose() {
        console.log('onAddNoteClose');
        this.setState({add_note: false});
        return;
    }

    render() {
        const {add_note} = this.state;
        //      <Modal show={show} onHide={handleClose}>
        return (
            <Modal show={add_note} onHide={this.onAddEventClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Modal body text goes here.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary">Close</Button>
                    <Button variant="primary">Save changes</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default EventModal;
