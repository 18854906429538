import {Logger} from "react-logger-lib";
import {HORIZONTAL, HOUR_HEIGHT_PX, VERTICAL, YYYY_MM_DD} from "../constants";
import Event from "../components/Event";
import {CardGroup} from "react-bootstrap";
import React from "react";
import {getTime, objIsNotEmpy} from "./utils";
import Planned from "../components/Planned";
import {STYLE_ERROR} from "../style";


localStorage.setItem('App.utils_ui', 'INFO '); //WARN INFO TRACE


export function getUnit(u) {
    return u.toString().concat("px");
}


export function getTranslation(x, y, positionOffset, unitSuffix) {
    let translation = `translate(${x}${unitSuffix},${y}${unitSuffix})`;
    if (positionOffset) {
        const defaultX = `${(typeof positionOffset.x === 'string') ? positionOffset.x : positionOffset.x + unitSuffix}`;
        const defaultY = `${(typeof positionOffset.y === 'string') ? positionOffset.y : positionOffset.y + unitSuffix}`;
        translation = `translate(${defaultX}, ${defaultY})` + translation;
    }
    return translation;
}


export function getRangeByHours(start_date, end_date) {
    let ranges = [];
    Logger.of('App.utils_date.getRangeByHours').info("start=", start_date, "end=", end_date);
    for (let d = getTime(start_date); d < getTime(end_date); d.add(1, 'hour')) {
        // console.log(d);
        let h = d.hours();
        let t = h < 12 ? 'AM' : 'PM';
        ranges.push([getTime(d), h.toString().padStart(2, "0"), t]);
    }
    Logger.of('App.utils_date.getRangeByHours').info("ranges", ranges);
    return ranges;
}

export function getRangeHours(date_start, date_end,
                              hour_height = HOUR_HEIGHT_PX,
                              dir = VERTICAL, current_hour = undefined) {
    let ranges = getRangeByHours(date_start, date_end);

    return createGridRow(ranges, hour_height, dir, current_hour);
}

export function createGridRow(ranges, hour_height = HOUR_HEIGHT_PX,
                              dir = VERTICAL, select_row = undefined) {
    Logger.of('App.utils_ui.createGridRow').info("ranges=", ranges, "dir=", dir);
    return ranges.map((v, k) => {
        let style = {}
        let row_id = "calendar_row_".concat(v[1]);
        if (k === select_row) {
            style.backgroundColor = "#a361cd";
        }
        if (dir === HORIZONTAL) {
            style.height = getUnit(700);
            style.weight = getUnit(hour_height);
            return (
                <td style={style}>{v[1]} {v[2]}</td>
            )
        } else {
            style.height = getUnit(hour_height);
            return (
                <tr key={row_id} style={style}>
                    <td>{v[1]} {v[2]}</td>
                </tr>
            )
        }
    });
}


// export function createCalendarButtonsBar(props) {
//     const {ranges, onRangeShift, onRangeChange} = props;
//     const {start: start_ranges, end: end_ranges} = ranges;
//     console.log('getButtonsBar ranges', start_ranges);
//     return (
//         <div>
//             <h4>{start_ranges} - {end_ranges}</h4>
//             <Button id="today_button_prev" onClick={onRangeShift}>Prev</Button>
//             <input id="today_range_start" type="date" value={start_ranges}
//                    onChange={onRangeChange}/>
//             <Button id="today_button_next" onClick={onRangeShift}>Next</Button>
//
//             {/*<Button id="today_button_add" onClick={this.onAddNoteShow}>Add</Button>*/}
//         </div>
//     )
// }

// newPositionCallback
export function createEvents(events, stream, asEvent = true) {
    if (!objIsNotEmpy(events)) {
        return '';
    }

    if (!objIsNotEmpy(stream)) {
        Logger.of('App.utils_date.createEvents').error('Stream is required');
        return (
            <div style={STYLE_ERROR}>Stream is required</div>
        );
    }

    return (
        <CardGroup>
            {Object.entries(events).map(value => {
                return (<Event event_id={value[1].id} stream_id={stream.id}/>)
            })}
        </CardGroup>
    );
}

export function createEventsHTML(events, stream, activities) {
    if (!objIsNotEmpy(events)) {
        return '';
    }

    if (!objIsNotEmpy(stream)) {
        Logger.of('App.utils_date.createEventsHTML').error('Stream is required');
        return (
            <div style={STYLE_ERROR}>Stream is required</div>
        );
    }

    let STYLE_EVENT_CONTENT = {
        paddingLeft:20
    };
    let events_by_start = Object.values(events).sort( (a, b) => {
        return (getTime(a.start) > getTime(b.start)) ? -1 : 1;
    }

    );
    return (
        <>
            {Object.entries(events_by_start).map( (value) => {
                const {start, note, content, activity: activityId} = value[1];
                let date = getTime(start).format(YYYY_MM_DD)
                let html = objIsNotEmpy(content) ? content : note;
                if (objIsNotEmpy(html)) {
                    let re = RegExp('#'.concat(stream.name), 'gi');
                    html = html.replaceAll(re, '<strong>'.concat('$&', '</strong>') )
                }
                let activity = activities[activityId].name
                // console.log(html)
                return (
                    <>
                        <div><strong>{date}</strong> activity:<strong>{activity}</strong></div>
                        <div dangerouslySetInnerHTML={{__html: html}} style={STYLE_EVENT_CONTENT}/>
                    </>)
            })}
        </>
    );
}

export function createPlanned(events, stream) {
    if (!objIsNotEmpy(events)) {
        return '';
    }

    if (!objIsNotEmpy(stream)) {
        throw 'Stream is required';
    }

    return (
        <CardGroup>
            {Object.entries(events).map(value => {
                return (<Planned event_id={value[1].id} stream_id={stream.id}/>)
            })}
        </CardGroup>
    );
}
