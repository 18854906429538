import Amplify from 'aws-amplify';
import {ConnectedRouter} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';

import awsconfig from './aws-exports';
import routes from './routes';

Amplify.configure(awsconfig);


const App = ({history}) => {
    return (
        <ConnectedRouter history={history}>
            {routes}
        </ConnectedRouter>
    )
}

App.propTypes = {
    history: PropTypes.object,
}

export default App;
// export default withAuthenticator(App);
