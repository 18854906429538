import {normalize, schema} from 'normalizr';
import streamsData from './streams.json';

export const streamSchema = new schema.Entity('streams');
export const parentStreamSchema = new schema.Entity('streams',{
    // properties: propertiesSchema,
    parent: streamSchema
});
export const streamsSchema = [streamSchema]


export const createTree = (objs) => {
    var roots = {};
    var nodes = {};
    Object.entries(objs).forEach( (value, index) => {
        const obj = value[1];
        Object.assign(nodes, {[obj.id]: obj});
        if (!('parent' in obj)) {
            Object.assign(roots, {[obj.id]: obj});
        } else {
            const parentNode = nodes[obj.parent];
            if (!('children' in parentNode)) {
                Object.assign(parentNode, {'children': [obj]});
            } else {
                parentNode.children.push(obj);
            }
        }
    })

    return roots;
}

const normalized = normalize(streamsData.streams, streamsSchema);
const streams = createTree(normalized.entities.streams);

export default streams;
