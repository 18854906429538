import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {Logger} from 'react-logger-lib';
import {client} from '../../api/client';
import {eventSchema, eventsSchema} from '../../app/schemas';
import {API_EVENT_POS_URL, API_EVENTS_URL, API_PLANNED_POS_URL} from '../../constants';
import {getEventURL, objIsNotEmpy} from '../../lib/utils';
import {calculateLayout} from '../layouts/layoutsSlice';
import {invalidateRange} from '../ranges/rangesSlice';

localStorage.setItem('App.eventsSlice', 'WARN '); //WARN INFO TRACE

export const eventsAdapter = createEntityAdapter();
const eventsInitialState = eventsAdapter.getInitialState({status: null, error: null});

const USE_JSON = true;

/**
 Returns events for:
 1. Today, filter by day
 2. Activities, last 3-5 events
 3. Stream, events for given tag, last week?
 */

// TODO: use one for all events,  construct /<stream>/<day>
export const getEvents = createAsyncThunk(
    'events/getEvents',
    async (data, {dispatch, getState}) => {
        Logger.of('App.eventsSlice.getEvents').info('data', data);
        const {stream, ranges} = data;
        let events;
        if (USE_JSON) {
            events = require('../../assets/2021-08.json');
        } else {

            let url = getEventURL(data);
            events = await client.get(url).then(
                (res) => res,
            );
        }
        const normalized = normalize(events, eventsSchema);
        return normalized.entities;
    },
);

export const getEventsForToday = createAsyncThunk(
    'events/getEventsForToday',
    async (data, {dispatch, getState}) => {
        Logger.of('App.eventsSlice.getEventsForToday').info('data', data);
        const {stream, ranges} = data;
        let new_range = await dispatch(invalidateRange(ranges));

        let events_action = await dispatch(getEvents(data));
        const {activity, tags, events} = events_action.payload;

        let layout_action = await dispatch(calculateLayout({events, ranges}));
        const positions = layout_action.payload;

        return positions;

        // .then( (res) => {
        //     const {events} = res.payload;
        //     return dispatch(calculateLayout(events))
        //     })
        // .then( (res) => {
        //     const {events_with_positions} = res.payload;
        //     console.log("asf");
        //     return events_with_positions;
        // });
        // // let positions = await dispatch(calculateLayout(events, {}));
        // return events;
        // let url = getEventURL(data);
        // let events = await client.get(url).then(
        //     (res) => res
        // )
        // const normalized = normalize(events, eventsSchema)
        // return normalized.entities
    },
);

export const moveEvent = createAsyncThunk(
    'events/moveEvent',
    async (position, {dispatch, getState}) => {
        Logger.of('App.eventsSlice.moveEvent').info('position=', position);
        let result = await client.post(API_EVENT_POS_URL, position)
            .then(res => res);
        return result;
    },
);

export const movePlanned = createAsyncThunk(
    'events/movePlanned',
    async (position, {dispatch, getState}) => {
        Logger.of('App.eventsSlice.movePlanned').info('position=', position);
        let result = await client.post(API_PLANNED_POS_URL, position)
            .then(res => res);
        return result;
    },
);

export const postEvent = createAsyncThunk(
    'events/postEvent',
    async (event, {dispatch, getState}) => {
        Logger.of('App.eventsSlice.postEvent').info('event=', event);
        let result = await client.post(API_EVENTS_URL, event)
            .then(res => res);
        const normalized = normalize(result, eventSchema);
        return normalized.entities;
    },
);

const eventsSlice = createSlice({
    name: 'events',
    initialState: eventsInitialState,
    reducers: {
        selectEvent(state, action) {
            Logger.of('App.eventsSlice.selectEvent').info('action', action);
            state.selected = action.payload;
        },
        setEventsPositions(state, action) {
            Logger.of('App.eventsSlice.setEventsPositions').info('action', action);
            let events = state.entities;
            Object.entries(action.payload).forEach(value => {
                Logger.of('App.eventsSlice.setEventsPositions.update').info('value', value);
                let event = events[value[1].event_id];
                event.position = {x: value[1].event_x, y: value[1].event_y};
            });
        },
    },
    extraReducers: {
        [getEvents.pending]: (state, action) => {
            state.error = null;
            state.status = 'loading';
        },
        [getEvents.fulfilled]: (state, {payload}) => {
            Logger.of('App.eventsSlice.getEvents.fulfilled').info('payload.events', payload.events);
            if (payload.events) {
                eventsAdapter.setAll(state, payload.events);
            } else {
                eventsAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success';
        },
        [getEvents.rejected]: (state, payload) => {
            Logger.of('App.eventsSlice.getEvents.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },

        [moveEvent.pending]: (state, action) => {
            state.status = 'loading';
        },
        [moveEvent.fulfilled]: (state, {payload}) => {
            Logger.of('App.eventsSlice.moveEvent.fulfilled').info('payload', payload);
            const {id: pos_id, x, y, event: event_id, stream} = payload;
            const {entities} = state;
            let event = entities[event_id];
            console.assert(objIsNotEmpy(event));
            // let {position, positions} = event;
            event.positions = event.positions.concat(pos_id);
            state.status = 'success';
        },
        [moveEvent.rejected]: (state, payload) => {
            Logger.of('App.eventsSlice.moveEvent.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },

        [postEvent.pending]: (state, payload) => {
            state.status = 'loading';
        },
        [postEvent.fulfilled]: (state, {payload}) => {
            Logger.of('App.eventsSlice.postEvent.fulfilled').info('action', payload);
            eventsAdapter.upsertMany(state, payload.events);
            state.status = 'success';
        },
        [postEvent.rejected]: (state, payload) => {
            Logger.of('App.eventsSlice.postEvent.rejected').info('action', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },
    },
});

export const {selectEvent, setEventsPositions} = eventsSlice.actions;

export const {
    pending: getEventsStart,
    fulfilled: getEventsDone,
    rejected: getEventsFailed,
} = getEvents;

export const {
    pending: getEventsForTodayStart,
    fulfilled: getEventsForTodayDone,
    rejected: getEventsForTodayFailed,
} = getEventsForToday;

export default eventsSlice.reducer;
