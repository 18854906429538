import {connect} from "react-redux";
import EventBase from "./EventBase";
import {Logger} from "react-logger-lib";
import {moveEvent} from "../features/events/eventsSlice";

localStorage.setItem('App.Event', 'WARN'); //WARN INFO TRACE

class Event extends EventBase {

    constructor(props) {
        super(props);
        Logger.of('App.Event.ctor').info('props', this.props);
    }

    savePosition(position) {
        this.props.dispatch(moveEvent(position));
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.Event.mapStateToProps').info('state', state);
    const {events: eventsState, layouts: layoutsState, streams: streamsState, activities: activitiesState} = state;
    const {entities: tags, selected_stream} = streamsState;
    const {entities: events} = eventsState;
    // const {entities: positions} = eventPositionsState;
    const {entities: layouts} = layoutsState;

    const {entities: activities} = activitiesState;
    return {events, layouts, activities, tags, selected_stream};
};


export default connect(mapStateToProps)(Event);
