// https://github.com/reduxjs/redux-essentials-example-app/blob/master/src/api/client.js
// A tiny wrapper around fetch(), borrowed from
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper

import {Logger} from "react-logger-lib";

localStorage.setItem('App.client', 'INFO'); //WARN INFO TRACE

export async function client(endpoint, {body, ...customConfig} = {}) {
    Logger.of('App.client').info('endpoint', endpoint);

    const headers = {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '127.0.0.1:7070', // if set when django does not allow
    }

    const config = {
        method: body ? 'POST' : 'GET',
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        },
    }
    Logger.of('App.client').info('endpoint', endpoint, 'method', config.method, 'body', body);

    if (body) {
        config.body = JSON.stringify(body)
    }

    let data
    try {
        const response = await window.fetch(endpoint, config)
        data = await response.json()
        if (response.ok) {
            return data
        }
        throw new Error(response.statusText)
    } catch (err) {
        return Promise.reject(err.message ? err.message : data)
    }
}

client.get = async function (endpoint, customConfig = {}) {
    return client(endpoint, {...customConfig, method: 'GET'})
}

client.post = function (endpoint, body, customConfig = {}) {
    const {id} = body;
    if(id) {
        return client(endpoint.concat(id, '/'), {...customConfig, body, method: 'PUT'})
    }
    return client(endpoint, {...customConfig, body, method: 'POST'})
}
