import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {Logger} from 'react-logger-lib';
import {client} from '../../api/client';
import {hostsSchema} from '../../app/schemas';
import {getStorageURL} from '../../lib/utils';

export const hostsAdapter = createEntityAdapter();
export const hostsInitialState = hostsAdapter.getInitialState({status: null, error: null});

// const USE_JSON = true;
const USE_JSON = false;

/**
 * Return hosts with properties and top level directories
 * @public
 */
export const getHosts = createAsyncThunk(
    'hosts/getHosts',
    async (data, {dispatch, getState}) => {
        Logger.of('App.docsSlice.getHosts').info('data', data);
        let events;
        if (USE_JSON) {
            const docs = require('../../assets/docs/docs.json');
            events = docs.Hosts;
        } else {
            let url = getStorageURL(data);
            events = await client.get(url).then(
                (res) => res,
            );
        }
        const normalized = normalize(events, hostsSchema);
        return normalized.entities;
    },
);

const hostsSlice = createSlice({
    name: 'hosts',
    initialState: hostsInitialState,
    reducers: {},
    extraReducers: {
        [getHosts.pending]: (state, action) => {
            console.log();
            state.error = null;
            state.status = 'loading';
        },
        [getHosts.fulfilled]: (state, {payload}) => {
            Logger.of('App.docsSlice.getHosts.fulfilled').info('payload', payload);
            if (payload.hosts) {
                hostsAdapter.setAll(state, payload.hosts);
            } else {
                hostsAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success';
        },
        [getHosts.rejected]: (state, payload) => {
            Logger.of('App.docsSlice.getHosts.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },
    },
});

export default hostsSlice.reducer;
