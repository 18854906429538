import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import {Logger} from 'react-logger-lib';
import {connect} from 'react-redux';
import {HOUR_HEIGHT_PX, MAIN, VERTICAL} from '../constants';
import {selectActivities} from '../features/activities/activitiesSlice';
import {getPlannedWithLayout, selectPlanned} from '../features/planned/plannedSlice';
import {selectRanges} from '../features/ranges/rangesSlice';
import {getTime, objIsNotEmpy} from '../lib/utils';
import {createEventsHTML, createPlanned, getRangeHours} from '../lib/utils_ui';
import {STYLE_CALENDAR_TABLE} from '../style';
import VisNetwork from './vis/VisNetwork';

// http://visjs.org/docs/timeline/#Configuration_Options
const options = {
    width: '100%',
    height: '100%',
    stack: false,
    showMajorLabels: true,
    showCurrentTime: true,
    zoomMin: 1000000,
    type: 'background',
    format: {
        minorLabels: {
            minute: 'h:mma',
            hour: 'ha',
        },
    },
};


class Plan extends React.Component {
    componentDidMount() {
        Logger.of('App.Plan.componentDidMount').info();
        const {ranges} = this.props;
        // this.props.dispatch(getPlannedWithLayout({ranges}));
    }

    render() {

        Logger.of('App.Plan.render').info();
        let hour_height = HOUR_HEIGHT_PX;
        let dir = VERTICAL;
        let current_hour = getTime().hour();

        const {ranges, planned, activities} = this.props;
        let components_planned = createPlanned(planned, MAIN);
        let loaded = objIsNotEmpy(planned);

        let components_html = createEventsHTML(planned, MAIN, activities);
        return (
            <VisNetwork/>
        );
    }
}


const mapStateToProps = (state) => {
    Logger.of('App.Plan.mapStateToProps').info('state', state);
    const ranges = selectRanges(state);
    const planned = selectPlanned(state);
    const activities = selectActivities(state);
    return {ranges, planned, activities};
};

export default connect(mapStateToProps)(Plan);

//             <div id="plan_component_id">
//                 <Container fluid>
//                     <Row>
//                         <VisNetwork/>
//                     </Row>
//                 </Container>
//             </div>

//                     <Row>
//                         <Col>
//                             <div id="plan_calendar_header">
//                             </div>
//                             <Table id="plan_calendar_table" striped bordered style={STYLE_CALENDAR_TABLE}>
//                                 <tbody>
//                                 {getRangeHours(ranges.start, ranges.end, hour_height, dir, current_hour)}
//                                 </tbody>
//                             </Table>
//                             <div id="today_planned_div_id">
//                                 {loaded ? components_planned : <h4>No Plan</h4>}
//                             </div>
//                         </Col>
//                         <Col>
//                             {loaded ? components_html : <h4>No Plan</h4>}
//                         </Col>
//                     </Row>