import {createSlice} from "@reduxjs/toolkit";
import {getToday} from "../../lib/utils";
import {Logger} from "react-logger-lib";


localStorage.setItem('App.rangesSlice', 'WARN'); //WARN INFO TRACE


// export const invalidateRangeAndEvents = createAsyncThunk(
//     'streams/selectStream',
//     async (data, {dispatch, getState}) => {
//         Logger.of('App.rangesSlice.invalidateRangeAndEvents').info('data', data);
//         const {stream, ranges} = data;
//         dispatch(invalidateRange(ranges))
//         dispatch(getEvents({stream, ranges}))
//     }
// )


const rangesSlice = createSlice({
    name: 'ranges',
    initialState: {
        start: getToday().start,
        end: getToday().end,
    },
    reducers : {
        invalidateRange(state, action) {
            Logger.of('App.rangesSlice.invalidateRange').info('action', action);
            state.start = action.payload.start;
            state.end = action.payload.end;
        }
    }
})

export const {invalidateRange} = rangesSlice.actions;

export const selectRanges = (state) => (state.ranges);

export default rangesSlice.reducer;

