import {createAsyncThunk, createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {normalize} from 'normalizr';
import {Logger} from 'react-logger-lib';
import {client} from '../../api/client';
import {filesSchema} from '../../app/schemas';
import {getStorageFilesURL} from '../../lib/utils';

export const itemsAdapter = createEntityAdapter();
export const itemsInitialState = itemsAdapter.getInitialState();

// const USE_JSON = true;
const USE_JSON = false;

/**
 * Return directory's properties and subdirectories
 *
 */
export const getFiles = createAsyncThunk(
    'files/getFiles',
    async (data, {dispatch, getState}) => {
        const {host, path} = data;
        let url = getStorageFilesURL(host, path);
        let items = await client.get(url).then(
            (res) => res,
        );
        const normalized = normalize(items.dir.files, filesSchema);
        return normalized.entities;
    },
);

const filesSlice = createSlice({
    name: 'files',
    initialState: itemsInitialState,
    reducers: {},
    extraReducers: {
        [getFiles.pending]: (state, action) => {
            console.log();
            state.error = null;
            state.status = 'loading';
        },
        [getFiles.fulfilled]: (state, {payload}) => {
            Logger.of('App.filesSlice.getFiles.fulfilled').info('payload', payload);
            if (payload.files) {
                // itemsAdapter.addMany(state, payload.items)
                // itemsAdapter.updateMany(state, payload.items)
                itemsAdapter.setAll(state, payload.files);
            }
            state.error = null;
            state.status = 'success';
        },
        [getFiles.rejected]: (state, payload) => {
            Logger.of('App.filesSlice.getFiles.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed';
        },
    },
});

export default filesSlice.reducer;
