import Badge from "react-bootstrap/Badge";
import {API_STREAM} from "../../constants";
import Button from "react-bootstrap/Button";
import React from "react";

export const Tags = (props) => {
    const {obj, tags = {}} = props.data;
    return (
        <>
            {Object.entries(tags).map((value, index) => (
                <Badge key={index}><a href={API_STREAM.concat(value[1], '/')}>{value[1]}</a></Badge>)
            )}
            &nbsp;
            <Badge key={"".concat('event_, id, _tag_new')}
                   variant="light" as={Button}
                   onClick={console.log('this.onAddTag')}>+</Badge>
        </>
    )
};
