import {combineReducers, createAsyncThunk, createEntityAdapter, createSlice} from "@reduxjs/toolkit";
import {Logger} from "react-logger-lib";
import {getPlannedURL, getToday, objIsNotEmpy} from "../../lib/utils";
import {client} from "../../api/client";
import {normalize} from "normalizr";
import {eventsSchema, layoutsSchema} from "../../app/schemas";
import {movePlanned} from "../events/eventsSlice";
import {MAIN} from "../../constants";
import {layoutEventsByDayTime} from "../../lib/utils_layout";

localStorage.setItem('App.plannedSlice', 'INFO '); //WARN INFO TRACE

export const plannedAdapter = createEntityAdapter();
const plannedInitialState = plannedAdapter.getInitialState({status: null, error: null});

export const plannedLayoutsAdapter = createEntityAdapter();
export const plannedLayoutsInitialState = plannedLayoutsAdapter.getInitialState();


// TODO: use one for all events,  construct /<stream>/<day>
export const getPlanned = createAsyncThunk(
    'planned/getPlanned',
    async (data={stream: MAIN, ranges: getToday()}, {dispatch, getState}) => {
        Logger.of('App.plannedSlice.getPlanned').info('data', data);
        let url = getPlannedURL(data);
        let events = await client.get(url).then(
            (res) => res
        )
        const normalized = normalize(events, eventsSchema)
        return normalized.entities
    }
)


export const calculatePlannedLayout = createAsyncThunk(
    'layouts/calculatePlannedLayout',
    async (data={}, {dispatch, getState}) => {
        Logger.of('App.plannedSlice.calculatePlannedLayout').info('data', data);
        let {events=selectPlanned(getState()), ranges=getToday()} = data;
        let positions = layoutEventsByDayTime(events, {ranges});
        // let positions = layoutChildren(events, {ranges});
        const normalized = normalize(positions, layoutsSchema);
        return normalized.entities;
    }
)


export const getPlannedWithLayout = createAsyncThunk(
    'planned/getPlannedWithLayout',
    async (data={}, {dispatch, getState}) => {
        Logger.of('App.plannedSlice.calculatePlannedLayout').info('data', data);
        // let action =
        await dispatch(getPlanned(data));
        // const {activity, events} = action.payload;
        // let layouts = await dispatch(calculatePlannedLayout({events, ...data}));
        // return action;
    }
)

const plannedSlice = createSlice({
    name: 'planned',
    initialState: plannedInitialState,
    reducers: {
    },
    extraReducers: {
        [getPlanned.pending]: (state) => {
            state.error = null;
            state.status = 'loading'
        },
        [getPlanned.fulfilled]: (state, {payload}) => {
            Logger.of('App.plannedSlice.getEvents.fulfilled').info('payload.getPlanned', payload.events);
            if (payload.events) {
                plannedAdapter.setAll(state, payload.events)
            } else {
                plannedAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success'
        },
        [getPlanned.rejected]: (state, payload) => {
            Logger.of('App.plannedSlice.getEvents.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed'
        },

        [movePlanned.pending]: (state) => {
            state.status = 'loading'
        },
        [movePlanned.fulfilled]: (state, {payload}) => {
            Logger.of('App.plannedSlice.movePlanned.fulfilled').info('payload', payload);
            const {id: pos_id, planned: planned_id} = payload;
            const {entities} = state;
            let event = entities[planned_id];
            console.assert(objIsNotEmpy(event));
            // let {position, positions} = event;
            event.positions = event.positions.concat(pos_id);
            state.status = 'success'
        },
        [movePlanned.rejected]: (state, payload) => {
            Logger.of('App.plannedSlice.movePlanned.rejected').info('payload', payload);
            state.status = 'failed'
        },
    }
})

const plannedLayoutsSlice = createSlice({
    name: 'layouts',
    initialState: plannedLayoutsInitialState,
    reducers: {
    },
    extraReducers: {
        [calculatePlannedLayout.pending]: (state) => {
            console.log();
            state.error = null;
            state.status = 'calculating'
        },
        [calculatePlannedLayout.fulfilled]: (state, {payload}) => {
            Logger.of('App.plannedLayoutsSlice.calculateLayout.fulfilled').info('payload.events', payload.events);
            if (payload.layouts) {
                plannedLayoutsAdapter.setAll(state, payload.layouts)
            } else {
                plannedLayoutsAdapter.removeAll(state);
            }
            state.error = null;
            state.status = 'success'
        },
        [calculatePlannedLayout.rejected]: (state, payload) => {
            Logger.of('App.plannedSlice.calculateLayout.rejected').info('payload', payload);
            const {error} = payload;
            state.error = error.message;
            state.status = 'failed'
        }
    },
})

const reducer = combineReducers({
    planned: plannedSlice.reducer,
    layouts: plannedLayoutsSlice.reducer,
})



export const {
    pending: getPlannedStart,
    fulfilled: getPlannedDone,
    rejected: getPlannedFailed
} = getPlanned;

export const {
    pending: calculatePlannedLayoutStart,
    fulfilled: calculatePlannedLayoutDone,
    rejected: calculatePlannedLayoutFailed
} = calculatePlannedLayout;


export const selectPlanned = (state) => {
    return state.planned.planned.entities;
}

export const selectLayouts = (state) => {
    return state.planned.layouts.entities;
}
// {
//     const {planned: plannedState, layouts: layoutsState, ranges} = state;
//     const {entities: planned} = plannedState;
// }

export default reducer;
