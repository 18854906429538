const COLOR = [
    "#9e0142",
    "#d53e4f",
    "#f46d43",
    "#fdae61",
    "#fee08b",
    "#e6f598",
    "#abdda4",
    "#66c2a5",
    "#3288bd",
    "#5e4fa2",
]

const C = (i) => ({color: COLOR[i], fontcolor: '#ffffff'});



const CELL = 200;
const D = 100;
const D1 = 20;

const X0 = 0;
const Y0 = 0;

const X = (i, d=0) => X0 + i * D + d*D1;
const Y = (i, d=0) => Y0 + i * D + d*D1;

const TASK = {
    // fixed: true,  //{x: 10, y: 20},
    shape: 'box',
    // color: {
    //     background: '#00ffff',
    // },

};

export const nodes = [

    {id: 2000, label: 'Prepare in Boston', x: X(-2), y: Y(-1), ...C(4), ...TASK},
    {id: 2010, label: 'OpenVPN', x: X(-1), y: Y(-2), ...C(3),},

    {id: 4000, label: 'Prepare in Kyiv', x: X(-2), y: Y(1), ...C(4), ...TASK},
    {id: 4010, label: 'Check Internet', x: X(-1, -1), y: Y(2, -1), ...C(5), ...TASK},
    {id: 4020, label: 'Check Monitor', x: X(-1), y: Y(2), ...C(5), ...TASK},
    {id: 4030, label: 'What I can buy', x: X(-1, 1), y: Y(2, 1), ...C(5), ...TASK},

    {id: 100, label: 'Kyiv', x: X(0), y: Y(0), ...C(6), ...TASK},

    {id: 3000, label: 'Do in Kyiv', x: X(2), y: Y(1), ...C(7), ...TASK},
    {id: 3010, label: 'Configure PROXY', x: X(2), y: Y(2, -1), ...C(8), ...TASK},
    {id: 3020, label: 'Configure Video Security', x: X(1), y: Y(2), ...C(8), ...TASK},
    {id: 31-0, label: 'Work', x: X(3), y: Y(2), ...C(9), ...TASK},

    {id: 5000, label: 'Do in Boston', x: X(2), y: Y(-1), ...C(7), ...TASK},
    {id: 5010, label: 'Use Proxy', x: X(3), y: Y(-1, -1), ...C(8), ...TASK},
    {id: 5020, label: 'Run local jobs', x: X(3), y: Y(-1), ...C(8), ...TASK},

];

export const edges = [
    {from: 2000, to: 100},
    {from: 2000, to: 2010},

    {from: 3000, to: 100},
    {from: 3010, to: 3000},
    {from: 3020, to: 3000},

    {from: 4000, to: 100},
    {from: 4000, to: 4010},
    {from: 4000, to: 4020},
    {from: 4000, to: 4030},

    {from: 5000, to: 100},


];
