import {useEffect, useState} from 'react';
import {Logger} from 'react-logger-lib';
import {useDispatch} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {getFiles} from '../../features/docs/filesSlice';
import {STYLE_ERROR} from '../../style';

localStorage.setItem('App.Files', 'INFO'); //WARN INFO TRACE

const Files = (props) => {
    const dispatch = useDispatch();
    const [files, setFiles] = useState({});

    const {host} = useParams();
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const path = query.get('path');

    // const {} = props;

    // const getDirectoryFromSearchParam = () => {
    //     const {location, items} = props;
    //     const {search} = location;
    //     if (search && items) {
    //         let dir = search.split('=')[1];
    //         return selectDirectoryByName(items, dir);
    //     }
    // };

    useEffect(() => {
        Logger.of('App.Files.useEffect').info();
        // let dir = getDirectoryFromSearchParam();
        // let dirId = dir ? dir.id : undefined;
        dispatch(getFiles({host, path}))
            .then((res) => {
                if (res && res.payload) {
                    setFiles(res.payload.files);
                }
            })
            .catch((err) => {
                console.log(err);
            });

    }, []);

    // let dir = this.getDirectoryFromSearchParam();
    // let dirName = dir ? dir.name : undefined;

    return (
        <h1 style={STYLE_ERROR}>
            Files for <strong>{path}</strong>
            {files && Object.entries(files).map((value, index) => {
                return <div>{value[1].id}</div>;
            })}
        </h1>
    );

};

// const mapStateToProps = (state) => {
//     Logger.of('App.Files.mapStateToProps').info('state', state);
//     const {hosts: hostsState, docs: docsState} = state;
//     const {hosts, properties, items} = hostsState;
//     // const {entities: docs} = docsState;
//     return {hosts, properties, items};
// };

export default Files;
