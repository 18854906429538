import {Logger} from "react-logger-lib";

import React, {Component} from "react";
import {Image} from "react-bootstrap";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import Badge from "react-bootstrap/Badge";
import {getTime, objIsNotEmpy} from "../lib/utils";
import {API_STREAM, CARD_IMAGE_SIZE, EVENT_OCCURRED_LEFT_PX, HHmm, MAIN, MMDD} from "../constants";
import Button from "react-bootstrap/Button";
import AddTagModal from "./AddTagModal";
import {getUnit} from "../lib/utils_ui";


localStorage.setItem('App.EventBase', 'WARN'); //WARN INFO TRACE

const STYLE_CARD = {
    display: 'inline-block',
    padding: '8px',
    position: 'absolute',
    borderStyle: 'solid',
    borderColor: '#00ff00',
    borderRadius: '8px',
    borderWidth: '2px',
};

class EventBase extends Component {

    static propsTypes = {
        event_id: PropTypes.string.isRequired,
        stream_id: PropTypes.string.isRequired
    }

    constructor(props) {
        super(props);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onAddTag = this.onAddTag.bind(this);
        Logger.of('App.EventBase.ctor').info('props', this.props);
    }

    /**
     * See https://github.com/STRML/react-draggable#draggable-props
     */
    onMouseUp(mouseEvent, coreEvent) {
        Logger.of('App.EventBase.onMouseUp').info('mouseEvent', mouseEvent);
        Logger.of('App.EventBase.onMouseUp').info('coreEvent', coreEvent);
        const {node, x, y} = coreEvent;

        // const {clientX, clientY} = mouseEvent;
        const {offsetTop, offsetLeft} = node;

        const {event_id, events} = this.props; // from constructor and store
        // let event = events[event_id];
        let stream = this.getStream();

        // let p = this.getEventExistingPosition(event, stream);
        // let position = {...p};
        let position = {};

        position.event = event_id;
        position.x = offsetLeft + x;
        position.y = offsetTop + y;
        position.stream = stream.id

        this.savePosition(position);
    }

    onAddTag() {
        Logger.of('App.EventBase.onAddTag').info('props', this.props);
        this.setState({add_tag: true});
    }

    savePosition(position) {
        console.assert(false, 'Class must overwrite savePosition()')
    }

    // getPositionStyle(props) {
    //     Logger.of('App.EventBase.getPositionStyle').trace('props', props);
    //
    //     let style = {};
    //     if (props) {
    //         if (props.x >= 0 || props.y >= 0) {
    //             style.position = 'absolute'; //'relative';  //'fixed';  //
    //             if (props.x >= 0) {
    //                 style.left = getUnit(props.x);
    //             }
    //             if (props.y >= 0) {
    //                 style.top = getUnit(props.y);
    //             }
    //         }
    //         if (props.w) {
    //             style.width = getUnit(props.w);
    //         }
    //         if (props.h) {
    //             style.height = getUnit(props.h);
    //         }
    //     }
    //
    //     return style;
    // }
    //
    // getEventExistingPosition(event, stream) {
    //     const {id, positions} = event;
    //     let pos;
    //     if (arrIsNotEmpty(positions) && stream) {
    //         const {positions: all_positions} = this.props;
    //          pos = Object.entries(positions).find( (value, index) => {
    //             let position = all_positions[value[1]];
    //             return (position.event == id && position.stream == stream.id);
    //         })
    //         if (pos) {
    //             pos = all_positions[pos[1]];
    //         }
    //     }
    //
    //     Logger.of('App.EventBase.getEventPosition').info('pos', pos);
    //     return pos;
    // }
    //
    // getEventPosition(event, stream) {
    //     let position = this.getEventExistingPosition(event, stream);
    //     if (typeof position === 'undefined' && stream) {
    //         const {new_pos_callback} = this.props;
    //         let new_pos = {"x": 20, "y": 20};
    //         if (typeof new_pos_callback !== 'undefined' && new_pos_callback) {
    //             new_pos = new_pos_callback(event)
    //         }
    //         position = {stream: stream.id, x: new_pos.x, y: new_pos.y, event: event.id};
    //         // this.savePosition(position);
    //     }
    //
    //     return position;
    // }
    //
    // getEventStyle(event) {
    //     Logger.of('App.EventBase.getEventStyle').trace('data', event);
    //     // const {position} = event;
    //     // let style = this.getPositionStyle(position);
    //     // // let style = {position: 'absolute'};
    //     return {...style, ...this.props.style};
    // }
    //
    // getCardStyle(event) {
    //     Logger.of('App.EventBase.getEventStyle').trace('data', event);
    //     return STYLE_CARD;  // , width: getUnit(350)};
    // }

    createHeader(event) {
        const {start, end} = event;

        let event_start = getTime(start);
        let event_end = end ? '-'.concat(getTime(end).format(HHmm)) : '';

        return <>
            <strong>{event_start.format(MMDD)}</strong> {event_start.format(HHmm)}{event_end}
            &nbsp;
            <strong onClick={this.onAddTag}>...</strong>
        </>;
    }
//             {/*<Button className="float-right" size="xs" bsSize="small" variant="outline-primary">...</Button>*/}
//            <b><a href={"api/events/".concat(id, '/')}>...</a></b>

    createTitle(event) {
        const {id, activity: activity_id} = event;
        if (activity_id) {
            const {activities} = this.props;
            let activity = activities[activity_id]
            return <>
                <b><a href={"http://localhost:7070/api/events/".concat(id, '/')}>{id}</a></b>:
                <b><a href={"http://localhost:7070/api/activities/".concat(activity.id, '/')}>{activity.name}</a></b>:
            </>;
        }
        return <>
            <b><a href={"http://localhost:7070/api/events/".concat(id, '/')}>{id}</a></b>:
        </>;
    }

    createBody(event) {
        const {note='', content} = event;
        if (content) {
            return <div dangerouslySetInnerHTML={{__html: content}}/>;
        } else {
            return <div>{note}</div>;
        }
    }

    createTags(event) {
        const {id, tags: tags_ids=[]} = event;
        const {tags} = this.props;
        Logger.of('App.EventBase.createTags').trace('tags', tags_ids);

        let tags_components = Object.entries(tags_ids).map( value => {
            let tag = tags[value[1]];
            let key = "".concat('event_', id, '_tag_', tag.id)
            return (
                <Badge key={key}><a href={API_STREAM.concat('?stream=', tag.id)}>{tag.name}</a></Badge>
            )}
        );
        // tags_components.append(<Badge><a href={API_TAG}>+</a></Badge>);
        tags_components = [...tags_components,
            (<Badge key={"".concat('event_', id, '_tag_new')}
            variant="success" as={Button} onClick={this.onAddTag}>+</Badge>)];
//(<Badge><a href={API_STREAM}>+</a></Badge>)
        return (tags_components);
    }

    getStream() {
        const {stream_id, tags} = this.props;
        return stream_id === 0 ? MAIN : tags[stream_id];
    }

    getImageStyle() {
        return {
            // position: 'relative',
            // left: this.getUnit(5), top: this.getUnit(5),
            width: getUnit(CARD_IMAGE_SIZE), height: getUnit(CARD_IMAGE_SIZE),
        };
    }

    getActivityImage() {
        const {event_id, events} = this.props;
        const event = events[event_id];
        const {activity: activity_id} = event;
        if (activity_id) {
            const {activities} = this.props;
            let activity = activities[activity_id]
            let imageSrc = "/media/".concat(activity.name.toLowerCase(), ".png");
            return (
                <Image src={imageSrc} style={this.getImageStyle()}/>
            )
        }
        return "";  //.concat("static/media/", this.state.data.activity, ".png")
    }

    render() {
        Logger.of('App.EventBase.render').info('props', this.props);
        const {event_id, events, layouts} = this.props;

        // get event
        console.assert(typeof events !== 'undefined' && typeof events[event_id] !== 'undefined', "Event must exists")
        const event = events[event_id];
        const {id} = event;

        // get stream
        let stream = this.getStream();
        console.assert(typeof stream !== 'undefined', "Stream must exists")

        // get position
        let position = objIsNotEmpy(layouts) ? layouts[id] : {x: EVENT_OCCURRED_LEFT_PX, y: 20};
        Logger.of('App.EventBase.render').warn('position', position);

        let rnd_id = Math.random().toString(36).substring(2, 15);
        let className = "handle".concat(rnd_id);
        // let style= {...STYLE_CARD, ...position, left: position.x, top: position.y, width:position.w, height: position.h}
        let key="key_".concat(className);
        let handle=".".concat(className);

        // let ss = String(JSON.stringify({x: position.x, y: position.y, h: position.h}));

        return (
            <Draggable key={key}
                       handle={handle}
                       onStop={this.onMouseUp}
                       axis="both"
                       defaultPosition={{x: position.x, y: position.y}}
            >
                <div data-event_id={id}
                    className={className}
                    style={{cursor: "move", ...STYLE_CARD, width:position.w, height: position.h}}>
                    {this.getActivityImage()}
                    {this.createBody(event)}
                    <AddTagModal show={false} />
                </div>
            </Draggable>
        );
    }
}




//                 <div data-event_id={id} style={style}>
//                     <Card style={this.getCardStyle(event)}>
//                         <Card.Header className={className} style={{cursor: "move"}}>
//                             {this.createTitle(event)}
//                             {this.createHeader(event)}
//                         </Card.Header>
//                         <Card.Title></Card.Title>
//                         <Card.Body className="w-auto p-3">{this.createBody(event)}</Card.Body>
//                         <Card.Footer>{this.createTags(event)}</Card.Footer>
//                     </Card>
//                     <AddTagModal show={false} />
//                 </div>


export default EventBase;
