import {useEffect, useState} from 'react';
import {Container} from 'react-bootstrap';
import Stream from '../Stream/index';
import streamsData from './Streams.data';

const Streams = ({streamsProp, theme}) => {
    const [streams, setStreams] = useState(streamsProp);

    useEffect(() => {
        if (!streamsProp) {
            setStreams(streamsData);
        }
    }, []);

    return (
        <Container fluid style={theme}>
            {streams && Object.entries(streams).map((value, index) => {
                const streamId = value[0];
                const stream = value[1];
                return (
                    <Stream key={streamId} stream={stream}/>
                );
            })}
        </Container>
    );
};

export default Streams;
