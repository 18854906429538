// Application menu and toolbars
export const STYLE_APP_NAVBAR = {
    // position: 'fixed',
    // top: 0,
    // left: 0,
    // width: '100%',
    overflow: 'auto',
    // overflow: 'hidden'
    // height: '100vh'
};

export const STYLE_APP_COMPONENT = {
    height: '90vh',
    overflowY: 'scroll'
    // height: '100vh'
};

export const STYLE_ERROR = {
    color: 'red',
    padding: 60
};



// Today components
export const STYLE_CALENDAR_TABLE = {
    position: 'absolute'
};



// Storage components
export const STYLE_DIRECTORY = {
    color: 'green',
    paddingLeft: 40
}

export const STYLE_DIRECTORIES = {
    borderWidth: '2px',
    borderColor: 'green'
};

export const STYLE_FILES = {
    color: 'darkgreen',
    paddingLeft: 60
}

export const STYLE_PROPERTY = {
    listStyleType: 'square'
}

export const STYLE_PROPERTIES = {
}

export const STYLE_HOST = {
    flexDirection: 'row'
}



// Projects Component
export const STYLE_PROJECT_BOX = {
    border: "2px solid darkgreen",
    // height: "20px",
    // display: "table-cell"
    // display: "table"
}

export const STYLE_PROJECT_ROW = {
    // flex: 1,
    // height: "20px"
    // display: "table"
    display: "flex",
    flexWrap: "wrap"
}

export const STYLE_PROJECT_COLUMN = {
    // display: "table-cell"
    display: "flex",
    flexDirection: "column"

    // flex: 1,
    // height: "20px"
}

export const STYLE_PROJECT_CONTAINER = {
    flex: 1,
    height: "20px"
}
