import React, {useState} from 'react';
import {Collapse} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

/**
 * This hook generate two components:
 * button: +/- for items
 * collapsed/extended area: for provided React componenets
 *
 * @param {{name: string, items: JSX.Element}|{initState: boolean, id, items: JSX.Element, onOpenItemLoad: onOpenItemLoad}} props
 */
const useCollapsedItems = (props) => {
    const {id, components, loadItemsOnOpen, isItemCollapsedInitState=false} = props;

    const [isItemCollapsed, setIsItemCollapseds] = useState(isItemCollapsedInitState);
    const onOpenItem = () => {
        if (!isItemCollapsed && loadItemsOnOpen) {
            loadItemsOnOpen(id, setIsItemCollapseds);
        }
        setIsItemCollapseds(!isItemCollapsed);
    };

    let area_id = 'area-collapse-'.concat(id);

    const button =
        <Button size="sm" variant="light"
                onClick={onOpenItem}
                aria-controls={area_id}
                aria-expanded={isItemCollapsed}>{isItemCollapsed ? '-' : '+'}</Button>;
    const area =
        <Collapse in={isItemCollapsed}>
            <div id={area_id}>
                {components}
            </div>
        </Collapse>;

    return [button, area];
};

export default useCollapsedItems;
