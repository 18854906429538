import React, {useEffect, useRef} from 'react';
import {Network} from 'vis-network';
// import {nodes, edges} from './jobs';
import {nodes, edges} from './travel';

export const style = {
    component: {
        width: '100%',
        height: '90vh',
    },
    network: {
        width: '100%',
        height: '100%',
    },
};

export const options = {
    physics: {
        stabilization: true,
    },
    nodes: {
        borderWidth: 4,
        size: 10,
        //fixed:true,
        physics: false,
    },
    edges: {
        arrows: {
            to: true,
        },
    },
    layout: {
        // randomSeed: false,
        hierarchical: {
            enabled: false,
            direction: 'LR',
        },
    },
};

const data = {
    nodes: nodes,
    edges: edges,
};


// https://stackoverflow.com/questions/40792649/rendering-vis-js-network-into-container-via-react-js
const VisNetwork = () => {


    const visJsRef = useRef(null);
    const network = useRef(null);

    useEffect(() => {
        network.current = new Network(visJsRef.current, data, {...options, ...style.network});
        network.current.on('dragEnd', (event) => {
            // Object.entries(event.nodes).forEach( (v,i) => {
            //     const n = v[1];
            //     console.log(nodes[n].label);
            // })
            // for (const n in event.nodes) {
            //     console.log(nodes[n].label);
            // }
            console.log('dragEnd', event.pointer.DOM);
        });
        // Use `network` here to configure events, etc
        console.log('network', network);
    }, [visJsRef, network]);  //, nodes, edges

    return <div style={style.component} ref={visJsRef}/>;
};

export default VisNetwork;