/*
 * Properties selector common for all objects
 */
export const selectEntityById = (state, id) => (state[id]);
export const selectEntitiesByIds = (state, ids) => (
    Object.entries(ids).map((value) => {
        return selectEntityById(state, value[1]);
    }));

export const selectPropertyByKeyFromProps = (props, key) => {
    return Object.values(props).find((value => {
        return value.key === key;
    })) || {};
};
export const selectPropertyByKeyFromIds = (state, ids, key) => {
    let props = selectEntitiesByIds(state, ids);
    return selectPropertyByKeyFromProps(props, key);
};
