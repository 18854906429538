import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import configureStore, {history} from "./app/store";


// https://github.com/supasate/connected-react-router#step-3
const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <App history={history} />
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// export {API_EVENT_POS_URL} from "./constants";
// export {API_STREAM_EVENTS_URL} from "./constants";
// export {API_STREAMS_URL} from "./constants";
// export {API_ACTIVITIES_HEALTH_STAT_URL} from "./constants";
// export {API_ACTIVITIES_URL} from "./constants";
// export {API_EVENTS_URL} from "./constants";