// https://medium.com/@davidtranwd/implement-tree-view-component-with-reactjs-and-styled-components-5eea3b1603cf
import {Checkbox} from '@material-ui/core';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {getDirectories} from '../../features/docs/dirsSlice';
import useCollapsedItems from '../../hooks/collapsed';
import {STYLE_DIRECTORY} from '../../style';
import {Tags} from '../common/Tags';
import {Directories} from './Directories';

const Directory = (props) => {
    const [directories, setDirectories] = useState({});
    const dispatch = useDispatch();

    const {host, root, selected = false} = props;
    let key = 'directory_id_'.concat(root.replaceAll('/', '_'));
    let tags = {tag: key};

    // const items = <Properties properties={properties}/>
    // let propertiesProps = {name, items};
    // const [buttonProps, areaProps] = useCollapsedItems(propertiesProps);

    const hostDirectoriesItems = <Directories host={host} root={root} directories={directories}/>;
    const loadItemsOnOpen = (host, setOpenItems) => {
        console.log('Call getDirectories from DIR');
        dispatch(getDirectories({host, dir: root}))
            .then((res) => {
                if (res && res.payload) {
                    setDirectories(res.payload.dirs);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [buttonDirectories, areaDirectories] = useCollapsedItems({
        id: host,
        components: hostDirectoriesItems,
        loadItemsOnOpen,
        isItemCollapsedInitState: false,
    });

    // const filesItems = <div style={STYLE_FILES}>Files placeholder</div>
    // let filesProps = {name: `FilesFor${host}`, items: filesItems};
    // const [buttonFiles, filesAreaProps] = useCollapsedItems(filesProps);

    const handleChange = (event) => {
        // console.log(event);
        console.log('new state', event.target.checked);
        // event.target.checked = !event.target.checked;
        // dispatch(postDirectories({host, root, !event.target.checked}))
    }

    return (
        <div key={key} style={STYLE_DIRECTORY}>
            <Checkbox checked={selected} onChange={handleChange}/>
            {buttonDirectories}
            {/*{buttonFiles}*/}
            {/*{buttonProps}*/}
            <Link to={`/files/${host}?path=${root}`}>
                {root}
            </Link>
            <Tags data={{tags}}/>
            {/*{areaProps}*/}
            {/*{filesAreaProps}*/}
            {areaDirectories}
        </div>
    );
};
export default Directory;
