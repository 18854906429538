import {Nav, Navbar} from "react-bootstrap";
// import Nav from "react-bootstrap/Nav";
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom'
import Graph from './components/Graph';
import Today from "./components/Today";
import Docs from "./components/Docs";
import Streams from "./components/Streams/index";
import Activity from "./components/Activity";
import HomePage from "./components/HomePage/HomePage";
import NoMatch from "./components/NoMatch";
import Plan from "./components/Plan";
import Files from "./components/storage/Files";
import VisNetwork from './components/vis/VisNetwork';
import VisTimeline from './components/vis/VisTimeline';
import {STYLE_APP_COMPONENT, STYLE_APP_NAVBAR} from "./style";
import Project from "./components/Project";
import GoogleSocialAuth from "./components/auth/GoogleSocialAuth";


const routes = (
    <div>
        <HashRouter>
            <Navbar fill="true" defaultExpanded bg="dark" variant="dark"  style={STYLE_APP_NAVBAR}> {/* expand="lg"  defaultExpanded*/}
                <Navbar.Brand href="#home">
                    <img src="matrix.jpg" width="32" height="32" className="d-inline-block align-top" alt="iDL"/> {' '}DL
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        <Navbar.Brand href="#today">Today</Navbar.Brand>
                        <Navbar.Brand href="#plan">Plan</Navbar.Brand>
                        <Navbar.Brand href="#project">Project</Navbar.Brand>
                        <Navbar.Brand href="#graph">Graph</Navbar.Brand>
                        <Navbar.Brand href="#graph2">Vis</Navbar.Brand>
                        <Navbar.Brand href="#timeline">Timeline</Navbar.Brand>
                        <Navbar.Brand href="#docs">Docs</Navbar.Brand>
                        <Navbar.Brand href="#stream">Stream</Navbar.Brand>
                        <Navbar.Brand href="#activity">Activity</Navbar.Brand>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Brand href="#login">Login</Navbar.Brand>
            </Navbar>
            <div id="app_component_id" style={STYLE_APP_COMPONENT}>
                <Switch>
                    <Route exact path="/today" component={Today}/>
                    <Route exact path="/plan" component={Plan}/>
                    <Route exact path="/project" component={Project}/>
                    <Route exact path="/graph" component={Graph}/>
                    <Route exact path="/graph2" component={VisNetwork}/>
                    <Route exact path="/timeline" component={VisTimeline}/>
                    <Route exact path="/docs" component={Docs}/>
                    <Route exact path="/stream" component={Streams}/>
                    <Route path="/stream/:stream_id" component={Streams}/>
                    <Route exact path="/activity" component={Activity}/>
                    <Route exact path="/" render={() => {
                        return (<Redirect to="/home"/>)
                    }}/>
                    <Route path="/home" component={HomePage}/>
                    <Route path="/files/:host" component={Files}/>
                    <Route path="/login" component={GoogleSocialAuth}/>
                    <Route component={NoMatch}/>
                </Switch>
            </div>
        </HashRouter>
    </div>
)
// return (this.state.isUserAuthenticated ?
//     <Redirect to="/home"/> : <Redirect to="/test1"/>)


// <Route path="/stream/:id" component={Stream}/>

// {/*<Route path="/stream" exact component={Stream}/>*/}
//

export default routes
