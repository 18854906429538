const style = {
    stream: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#ff84d7',
        borderRadius: 7,
        padding: 3,
        margin: 3,
    },
    container: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: '#e23030',
        borderRadius: 5,
        padding: 10,
        margin: 10,
    },
    none: {}
};

export default style;
