import {Logger} from "react-logger-lib";
import {CARD_WIDTH_PX, EVENT_OCCURRED_LEFT_PX, HOUR_HEIGHT_PX, VERTICAL} from "../constants";
import {getTime, objIsNotEmpy} from "./utils";

localStorage.setItem('App.eventsSlice', 'INFO '); //WARN INFO TRACE


/**
 * Converts milliseconds to row in hours
 * @param msecs
 * @param row_height
 * @returns {number}
 */
export function msec_to_px(msecs, row_height) {
    let pos_hours = msecs / 1000 / 60 / 60;
    return pos_hours * row_height;
}

/**
 * Layout events based on start-end times for a day
 * @param events
 * @param props
 * @returns {*}
 */
export function layoutEventsByDayTime(events, props) {
    Logger.of('App.utils_layout.layoutOccurredEvents').info('events', events, 'props', props);
    if (!objIsNotEmpy(events)) {
        return {}
    }
    const {
        ranges,
        dir = VERTICAL,
        height = HOUR_HEIGHT_PX, left = EVENT_OCCURRED_LEFT_PX
    } = props;
    let left_pos = left ? left : (dir === VERTICAL ? 0 : "-30px");
    let prime_axix = dir === VERTICAL ? 'y' : 'x';
    let secondary_axix = dir === VERTICAL ? 'x' : 'y';
    let prime_shape = dir === VERTICAL ? 'h' : 'w';
    let secondary_shape = dir === VERTICAL ? 'w' : 'h';

    Logger.of('App.utils_layout.layoutOccurredEvents').info('ranges', ranges);

    let range_start_time = getTime(ranges.start).valueOf();
    let range_end_time = getTime(ranges.end).valueOf();

    // let new_positions = Object.values(events).forEach( (value, index) => {
    let new_positions = [];
    Object.entries(events).forEach((value, index) => {
        // for (let i = 0; i < events.length; i++) {
        // let key = value[0];
        let event = value[1];
        let {id, start, end} = event;

        let event_start = getTime(start).valueOf();
        let event_end = end ? getTime(end).valueOf() : undefined;

        // check range
        if (event_end < range_start_time || event_start > range_end_time) {
            return value;
            // continue;
        }

        let new_position = {};
        // set id as event, normalizr does not work right without id
        new_position['id'] = id;
        new_position['event_id'] = id;

        if (event_start < range_start_time) {
            // Logger.of('App.utils_layout.layoutOccurredEvents').info('event_start < range_start_time', event_start, range_start_time);
            event_start = range_start_time;
        }
        if (event_end > range_end_time) {
            // Logger.of('App.utils_layout.layoutOccurredEvents').info('event_end > range_end_time', event_end, range_end_time);
            event_end = range_end_time;
        }

        let event_pos_msecs = event_start - range_start_time;

        new_position[secondary_axix] = left_pos;
        new_position[prime_axix] = msec_to_px(event_pos_msecs, height);
        if (event_end !== undefined) {
            new_position[prime_shape] = msec_to_px(event_end - event_start, height);
        }
        new_position[secondary_shape] = CARD_WIDTH_PX;

        Logger.of('App.utils_layout.layoutOccurredEvents').info(
            'set event[', index, '] ', secondary_axix, prime_axix, prime_shape,
            event[secondary_axix], event[prime_axix], event[prime_shape]);

        // new_positions[key] = {position: new_position, ...event};
        // new_positions[key] = new_position;
        new_positions.push(new_position);

        // check previous end time and set current start if not present
        // if (i > 0 && !(prime_shape in events[i-1])) {
        //     events[i-1][prime_shape] = event[prime_axix] - events[i-1][prime_axix];
        // }
    });

    Logger.of('App.utils_layout.layoutOccurredEvents').info('new_positions', new_positions);

    // return arrayToIdMap(new_positions, 'event_id');
    return new_positions;
    // Logger.of('App.utils_layout.layoutOccurredEvents').trace('output', tasks_events);
}


export const ChildPositionHint = {
    New: 0,
    Child: 1,
};


/**
 *
 * @param stack - array of [[x0, y0], [x1, y1],...]
 * @param xy - pair [x, y]
 * @returns [stack_updated, hint]
 */
export function position_child(stack, xy) {
    if (stack.length === 0) {
        stack.push(xy);
        return [stack, ChildPositionHint.New];
    }

    let prev_xy = undefined;
    while (stack[stack.length - 1][0] + stack[stack.length - 1][1] <= xy[0]) {
        prev_xy = stack.pop()
        if (stack.length === 0) {
            stack.push(xy);
            return [stack, ChildPositionHint.New];
        }
    }

    prev_xy = stack.pop();
    if (prev_xy[0] + prev_xy[1] <= xy[0]) {
        stack.push(xy);
        return [stack, ChildPositionHint.New];
    } else {
        stack.push(prev_xy);
        stack.push(xy);
        return [stack, ChildPositionHint.Child];
    }

    // return [stack, "No cases?"];
}


/**
 * Layout events in current container:
 * - based on event's start/end values calculates position
 *   inside the container based on container's range
 *   and row/height/hour(time, could be days)
 * @returns {[]}
 */
export function layoutChildren(events={}, props={}) {
    Logger.of('App.utils_layout.layoutChildren').info('events', events);
    if (!objIsNotEmpy(events)) {
        return {}
    }
    const {
        ranges,
        dir = VERTICAL,
        height = HOUR_HEIGHT_PX, left = EVENT_OCCURRED_LEFT_PX
    } = props;
    let left_pos = left ? left : (dir === VERTICAL ? 0 : "-30px");
    let prime_axix = dir === VERTICAL ? 'y' : 'x';
    let secondary_axix = dir === VERTICAL ? 'x' : 'y';
    let prime_shape = dir === VERTICAL ? 'h' : 'w';
    let secondary_shape = dir === VERTICAL ? 'w' : 'h';

    // const {range_start, range_in_days, row_height = 100} = props;
    let new_positions = [];
    let range_start_time = getTime(ranges.start).valueOf();
    let range_end_time = getTime(ranges.end).valueOf();

    // let range_end = getNext(range_start,'day', range_in_days);
    // let range_start_time = getFloorHour(range_start);
    // let range_end_time = getNext(range_start,'day', range_in_days); // getCeilHour(range_end);
    // let range_hours = getDiffHours(range_start, range_end);

    // Logger.of('App.utils_layout.layoutChildren').info(
    //     '\n\trange_start=', range_start,
    //     '\n\trange_end=', range_end,
    //     '\n\trange_hours=', range_hours,
    //     '\n\trange_start_time=', range_start_time, moment.utc(range_start_time).toISOString(),
    //     '\n\trange_end_time=', range_end_time, moment.utc(range_end_time).toISOString());

    // let LEFT_TOP = 100;
    let NODE_STEP = 250;
    // let NODE_WIDTH = 300;
    let y1 = 0;
    let stack = [];
    let event_pos_msecs = 0;

    Object.entries(events).forEach((value, index) => {
        // for (let i = 0; i < events.length; i++) {
        // let key = value[0];
        let event = value[1];
        // let event = events[i];
        let {id, start, end} = event;

        let event_start = getTime(start).valueOf();
        let event_end = end ? getTime(end).valueOf() : undefined;

        // let new_event = {...events[i]};
        // let event_start = moment.utc(new_event.start).valueOf();
        // let event_end = moment.utc(new_event.end).valueOf();

        // Logger.of('App.utils_layout.layoutChildren').trace(i,
        //     '\n\tevent_start=', event_start, moment.utc(event_start).toISOString(),
        //     '\n\tevent_end=', event_end, moment.utc(event_end).toISOString(),
        // );

        // check range
        if (event_start < range_start_time) {
            event_start = range_start_time;
            // console.log('layoutChildren event changed start time:', i,
            //     '\n\tevent_start', moment.utc(event_start).toISOString());
        }
        if (event_end > range_end_time) {
            event_end = range_end_time;
            // console.log('layoutChildren event changed end time:', i,
            //     '\n\tevent_end', moment.utc(event_end).toISOString());
        }

        event_pos_msecs = event_start - range_start_time;
        let event_pos_mins = Math.round(event_pos_msecs / 1000 / 60);
        let event_height_msecs = event_end - event_start;

        let result = position_child(stack, [event_pos_msecs, event_height_msecs]);
        stack = result[0];
        let hint = result[1];
        if (hint === ChildPositionHint.New) {
            y1 = left_pos;
        } else if (hint === ChildPositionHint.Child) {
            y1 = left_pos + (stack.length - 1) * NODE_STEP;
        }

        let event_pos = msec_to_px(event_pos_msecs, height);
        let event_height = msec_to_px(event_height_msecs, height);

        // if(!new_event['event_id']) {
        //     new_event['event_id'] = i;
        // }
        let new_position = {};
        // set id as event, normalizr does not work right without id
        new_position['id'] = id;
        new_position['event_id'] = id;

        new_position[secondary_axix] = y1; // start_x;
        new_position[prime_axix] = event_pos;
        new_position[secondary_shape] = CARD_WIDTH_PX;
        new_position[prime_shape] = event_height;
        Logger.of('App.utils_layout.layoutChildren').trace(
            '\n\tevent_pos=', event_pos,
            '\n\tevent_pos_mins=', event_pos_mins, 'minutes',
            '\n\tevent_height=', event_height, 'minutes',
            "\n\t", new_position);
        new_positions.push(new_position);
    });

    return new_positions;
}


// export function layoutEventsWithoutPositionAsStack(events, dispatch) {
//     // list of objects from database
//     let x = 120;
//     let y = 100;
//     let step = 20;
//     let new_positions = [];
//     Object.entries(events).forEach( value => {
//         let event = value[1];
//         const {position} = event;
//         if (typeof position === 'undefined') {
//             new_positions.push({event_id: event.id, event_x: x, event_y: y, stream: MAIN.id});
//             x += step;
//             y += step;
//         }
//     });
//     // if (arrIsNotEmpty(new_positions)) {
//     //     dispatch(setEventsPositions(new_positions));
//     // }
//
//     return events;
// }
